import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// redux
import { login, logout, testAuth } from '../redux/slices/authDal';

// ----------------------------------------------------------------------

useAuth.propTypes = {
    method: PropTypes.oneOf(['jwt', 'firebase'])
};

export default function useAuth() {


    // JWT Auth
    const dispatch = useDispatch();
    
    const {user, isLoading } = useSelector((state) => state.authDal);
    const isAuthenticated = testAuth();

    return {
        method: 'da-jwt',
        user,
        isLoading,
        isAuthenticated,
        loginWithGoogles: (data) => {
            dispatch(login({data}));
        },

        loginWithAzure: (data) => {
            // console.log('here 1234');
            dispatch(login({data}));
        },

        login: ({ email, password }) => {
            dispatch(
                login({
                    email,
                    password
                })
            );
        },
        logout: () => {
            dispatch(logout());
            // dispatch(getInitialize());
        },

        resetPassword: () => {},

        updateProfile: () => {}
    };





}
