import React, { useContext } from "react";
import { BrandContext } from "../../../contexts/BrandContext";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { Formik } from 'formik';
import axios from '../../../utils/axios';
import BasicInputField from "../../form/BasicInputField";
import { cWarn } from "../../CustomLogger";
import { getValidationFromFields, initialValues } from "../../UniversalComponents";
import { getDefaultMatterName } from "./MatterUtilities";

export default function MatterNameDialogue(props){

    const brandContext = useContext(BrandContext);

    const {
        textContent:{
            matterNameLabel:dialogueTitle='',
            save='',
            cancel=''
        }
    } =  brandContext;

    const {
        showDialogue,
        setShowDialogue,  
        context:{
            loadData,
            matterId,
            details:{
                matterData
            },
            details
        },     
    } = props;

    let matterName = '';
    if(matterData){
        ({matterName} = matterData);
    } 
    if(matterName === '' || matterName === null){
        matterName = getDefaultMatterName(details)
    }
   

    const formFields = {
        matterName:{
            label:'matterNameLabel',
            default:matterName,
            required:false
        }
    };



    return(
        <Dialog open={showDialogue} onClose={()=>{setShowDialogue(false)}} fullWidth className="brandedDialogue">
            <DialogTitle>
                {dialogueTitle}
            </DialogTitle>
            <Formik
                initialValues={initialValues(formFields)}
                validationSchema={getValidationFromFields(formFields)}
                onSubmit={(values)=>{
                    const apiPath = `${process.env.REACT_APP_API_URL}/setmattername/${matterId}`;
                    axios.post(apiPath,values,{
                        timeout: 300000,
                        withCredentials:true
                    }).then((res) => {
                        const {
                            data:{
                                success=false
                            }
                        } = res;
                        if(success){
                            setShowDialogue(false)
                            loadData();
                        }
                        else{
                            cWarn('There was a problem saving the new contact');
                        }
                        
                    }).catch(err => {
                        cWarn(err); 
                    });
                }}
            >
                {props => (
                    <>
                        <DialogContent>
                            <form onSubmit={props.handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12} className="inputHolder">
                                        <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName={`matterName`} variant="outlined" sx={{width:'100%'}}/>  
                                    </Grid>
                                </Grid>
                            </form>     
                        </DialogContent>
                        <DialogActions>
                        <Button variant="outlined" onClick={()=>{setShowDialogue(false)}}>{cancel}</Button>
                            <Button variant="outlined" onClick={props.handleSubmit}>{save}</Button>
                        </DialogActions>
                    </>
                )}
            </Formik>
        </Dialog>
    );

}