import CreateValuation from "./components/pages/valuations/CreateValuation";
import Dashboard from "./components/pages/valuations/Dashboard";
import PCDashboard from "./components/pages/pcdashboard/WidgetsOuter";
import ValuationDetails from "./components/pages/valuations/ValuationDetails";
import AllValuationsPage from "./components/ReportViews/Valuations/ResultsPageOuter";
import AllPCMattersPage from "./components/ReportViews/Matters/ResultsPageOuter";
import PCMatter from "./components/pages/matters/MatterDetails";
import NewPCMatter from "./components/pages/matters/CreateMatter";
import ArchivedPCMattersPage from "./components/ReportViews/Matters/ArchivedResultsPageOuter";
import ArchivedValuationsPage from "./components/ReportViews/Valuations/ArchivedResultsPageOuter";
import ProspectsMattersPage from "./components/ReportViews/Matters/ProspectResultsPageOuter";
import AllContactsPage from "./components/ReportViews/Contacts/ResultsPageOuter";
import Contact from "./components/pages/contacts/ContactDetails";
import OpenTask from "./components/Widgets/Tasks/OpenTask";

export const navConfig = {
    'eaCo' : [
        {
            path:'/',
            label:'linksDashboard',
            componemt:AllValuationsPage
        },
        {
            path:'/valuations',
            label:'linksValuations',
            subNav:[
                {
                    path:'/valuations/all',
                    label:'dbNavAllValuations',
                    componemt:AllValuationsPage
                },
                {
                    path:'/valuation/:valuationId',
                    nav:false,
                    exact:false,
                    componemt:ValuationDetails
                },
                {
                    path:'/valuation/:valuationId/:tabKey',
                    nav:false,
                    exact:false,
                    componemt:ValuationDetails
                },
                {
                    path:'/valuations/newvaluation',
                    label:'dbNavNewValuation',
                    componemt:CreateValuation
                },
                {
                    path:'/valuations/archived',
                    label:'dbNavArchivedValuations',
                    componemt:ArchivedValuationsPage
                }
            ]
        },
        {
            path:'/properties',
            label:'linksProperties'
        },
        {
            path:'/reporting',
            label:'linksReporting'
        },
        {
            path:'/contacts',
            label:'linksContacts'
        }
    ],
    'pcCo' : [
        {
            path:'/',
            label:'linksDashboard',
            componemt:PCDashboard
        },
        {
            path:'/matters',
            label:'linksMatters',
            subNav:[
                {
                    path:'/matters/all',
                    label:'dbNavAllMatters',
                    componemt:AllPCMattersPage
                },
                {
                    path:'/matter/:matterId/',
                    nav:false,
                    exact:false,
                    componemt:PCMatter
                },
                {
                    path:'/matter/:matterId/:tabKey',
                    nav:false,
                    exact:false,
                    componemt:PCMatter
                },
                {
                    path:'/matters/newmatter',
                    label:'dbNavNewMatter',
                    componemt:NewPCMatter
                },
                {
                    path:'/matters/archived',
                    label:'dbNavArchivedMatters',
                    componemt:ArchivedPCMattersPage
                }
            ]
        },
        {
            path:'/contacts',
            label:'linksContacts',
            componemt:AllContactsPage
        },
        {
            path:'/contact/:contactId',
            nav:false,
            exact:false,
            componemt:Contact
        },
        {
            path:'/contact/:contactId/:tabKey',
            nav:false,
            exact:false,
            componemt:Contact
        },
        {
            path:'/tasks/:task',
            nav:false,
            exact:false,
            componemt:OpenTask
        },

    ]
};

export const getNavConfig = (mode) =>{



}