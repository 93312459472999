import React, { useContext } from "react";
import {
    Box,
    Button, Grid, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from "@mui/material";
import { Launch } from "@mui/icons-material/index";
import { BrandContext } from "../../../contexts/BrandContext";
import { CardContainer, LoadingBox } from "../../UniversalComponents";

import { getAddressFields } from "./Handler";

import { MattersContext } from "./MattersContext";
import CustomMenuList from "../../CustomMenuList";
import BasicInputField from "../../form/BasicInputField";
import { Link } from "react-router-dom";
import { cWarn } from "../../CustomLogger";
import { getDefaultMatterName } from "./MatterUtilities";


export default function Results(props){

    const context = useContext(MattersContext);
    const brandContext = useContext(BrandContext);
    

    const searchArr = [];
    const fields = getAddressFields(context,{});
    const keys = Object.keys(fields);
    const values = Object.values(fields);
    
    for (let i = 0; i < keys.length; i += 1) {
        if(props.searchParams.get(keys[i])){

            let flagLabel = props.searchParams.get(keys[i]);
            if('type' in values[i]){
                if(values[i].type === 'select'){
                    try{
                        for(let j=0; i<values[i].options.length; j+=1){
                            if(`${values[i].options[j].value}` === `${props.searchParams.get(keys[i])}`){
                                flagLabel = values[i].options[j].label;
                                break;
                            }
                        }
                    }
                    catch(e){
                        console.warn(e);
                    }
                }
                if(values[i].type === 'checkbox') {
                    if(props.searchParams.get(keys[i])){
                        flagLabel = '';
                    }
                }
                if(values[i].chip === false) {
                    flagLabel = '';
                }
            }
            if(flagLabel){
                searchArr.push({
                    val: flagLabel,
                    flag: keys[i]
                });
            }

        }
    }

    const {
        textContent:{
            valuationsTableRefNumber='',
            valuationsTableClientName='',
            fieldsMatterType='',
            applyFiltersButton='',
            openButton='',
            filterBy='',
            assignedUsers='',
            targetDate='',
            sourceOfBusiness=''
        },
        textContent
    } = brandContext;

    const getFirstCharacter = (inputString) => inputString.length > 0 ? inputString[0].toUpperCase() : '';

    return (
        <>
            <CardContainer className="resultsCard">
                {context.isLoading ?
                    <LoadingBox/>
                    :
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                                <BasicInputField formik={props.formik} formFields={props.formFields} fieldName="textSearch" variant="outlined" className="cardSearch"/>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Typography variant="h3">{filterBy}</Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <BasicInputField formik={props.formik} formFields={props.formFields} fieldName="selectMatterType" variant="outlined" className="cardSearch"/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{display:'flex'}}>
                                    <BasicInputField formik={props.formik} formFields={props.formFields} fieldName="useDateRange" variant="outlined" className="cardSearch" sx={{width:'100%'}}/>
                                    <BasicInputField formik={props.formik} formFields={props.formFields} fieldName="fromDate" variant="outlined" className="cardSearch" sx={{width:'100%', marginRight:2}} disabled={props.formik.values.useDateRange ? false : true} />
                                    <BasicInputField formik={props.formik} formFields={props.formFields} fieldName="toDate" variant="outlined" className="cardSearch" sx={{width:'100%'}} disabled={props.formik.values.useDateRange ? false : true}/>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <BasicInputField formik={props.formik} formFields={props.formFields} fieldName="user" variant="outlined" className="cardSearch" sx={{width:'100%', marginTop:0}}/>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <BasicInputField formik={props.formik} formFields={props.formFields} fieldName="sourceOfBusiness" variant="outlined" className="cardSearch" sx={{width:'100%', marginTop:0}}/>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Box sx={{ minWidth: 300 }}>
                                        
                                    <Button
                                        sx={{float:'right', marginTop:3}}
                                        variant="contained"
                                        className="filterButton"
                                        onClick={()=>{props.formik.handleSubmit();}}
                                        
                                    >
                                        {applyFiltersButton}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </>
                }
            
                {(!context.isLoading) &&
                <>

                        <TableContainer className="bigWideTable">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{valuationsTableRefNumber}</TableCell>
                                        <TableCell>{assignedUsers}</TableCell>
                                        <TableCell>{valuationsTableClientName}</TableCell>
                                        <TableCell>{fieldsMatterType}</TableCell>
                                        <TableCell>{targetDate}</TableCell>
                                        <TableCell>{sourceOfBusiness}</TableCell>
                                        <TableCell/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {context.results.map((row, index) => {

                                        const {
                                            id,
                                            client,
                                            matterData,
                                            matterType:{
                                                name:matterTypeName=''
                                            },
                                            matterUserRoles=[]
                                        } = row;
                                                      
                                        
                                        let clientName = '';
                                        let clientMobile = '';
                                        let clientEmail = '';
                                        if(client){
                                            try{
                                                if('primaryContact' in client){
                                                    if(client.primaryContact){
                                                        ({
                                                            primaryContact:{
                                                                fullName:clientName,
                                                                email1:clientEmail,
                                                                mobile:clientMobile
                                                            }
                                                        } = client);
                                                    }
                                                }
                                            }
                                            catch(e){
                                                cWarn(e)
                                            }
                                        }

                                        let dueDate = '';
                                        let sourceOfBusinessLabel = '';
                                        let matterName = '';
                                        if(matterData){
                                            ({dueDate,matterName} = matterData);
                                            if(matterData.howDidYouHear){
                                                sourceOfBusinessLabel = matterData.howDidYouHear.textKey;
                                            }
                                        } 
                                        if(matterName === '' || matterName === null){
                                            matterName = getDefaultMatterName(row);
                                        }
                                        let dueDateFormatted = '';
                                        if(dueDate){
                                            const dateObj = new Date(dueDate);
                                            const options = { year: 'numeric', month: 'short', day: 'numeric' };
                                            dueDateFormatted = new Intl.DateTimeFormat('en-GB', options).format(dateObj);
                                        }


                                        return(
                                        <TableRow  className={`stripyRow`} key={index}>
                                            <TableCell>
                                                <Button component={Link} to={`/matter/${id}`}>{id}</Button>
                                            </TableCell>
                                            <TableCell>
                                                {matterUserRoles.map((userRole,index)=>{
                                                    const{
                                                        bdUser:{
                                                            firstName,
                                                            lastName,
                                                            userFirmsLinks=[]
                                                        }
                                                    } = userRole;
                                                    let initials = '';
                                                    if(userFirmsLinks.length > 0){
                                                        const {
                                                            customInitials=''
                                                        } = userFirmsLinks[0];
                                                        if(customInitials !== ''){
                                                            initials = customInitials;
                                                        }
                                                    }
                                                    if(!initials){
                                                        initials = `${getFirstCharacter(firstName)}${getFirstCharacter(lastName)}`;
                                                    }
                                                    const isLast = index === matterUserRoles.length-1;
                                                    return (
                                                        <Typography key={index} component="span">
                                                            {initials}{!isLast && '/'}
                                                        </Typography>
                                                    )

                                                })}
                                            </TableCell>
                                            <TableCell>
                                                <Typography className="blueBold">{clientName}</Typography>
                                                <Typography variant="smallText" className="subtleLink" onClick={()=>{window.open(`mailto:${clientEmail}`)}}>{clientEmail}</Typography><br/>
                                                <Typography variant="smallText" className="subtleLink" onClick={()=>{window.open(`tel:${clientMobile}`)}}>{clientMobile}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                {matterTypeName}<br/>
                                                {matterName}
                                            </TableCell>
                                            <TableCell>
                                                {dueDateFormatted}
                                            </TableCell>
                                            <TableCell>
                                                {textContent[sourceOfBusinessLabel]}
                                            </TableCell>
                                            <TableCell>
                                                <Box sx={{display:'flex', justifyContent:'end'}}>
                                                    <CustomMenuList
                                                        size="medium"
                                                        id={row.id}
                                                        context={context}
                                                        options={[
                                                        {
                                                            text: openButton,
                                                            iconComponent: <Launch/>,
                                                            to:`/matter/${id}`,
                                                            branded: true
                                                        }
                                                    ]}/>
                                                </Box>
                                            </TableCell>
                                        </TableRow>);
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 30, 60, 100,500,1000]}
                            component="div"
                            count={context.resTotal}
                            rowsPerPage={context.searchConfig.r}
                            page={context.searchConfig.p}
                            onPageChange={(e,p) => {
                                context.setResPage(p);
                            }}
                            onRowsPerPageChange={(r) => {
                                context.setResSize(r.target.value);
                            }}
                        />

                </>
                

                }
                </CardContainer>
        </>
    );
}