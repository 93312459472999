import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

export default function TinyMce(props) {

    const {
        fieldName,
        formik:{
            values,
            handleChange,
        }
    } = props;

  const editorRef = useRef(null);




  return (
    <>
      <Editor
        apiKey='8tp541kx7fhqltav7ikr2qe82dkbfnb5wljep3t6l26zhkja'
        onInit={(evt, editor) => editorRef.current = editor}
        value={values[props.fieldName]}
        onEditorChange={(e) => {
            handleChange({ target: { name: fieldName, value: e } })
        }}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
          ],
          toolbar: 'undo redo ' +
            'bold italic forecolor | ' +
            'numlist bullist',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
      />
      
    </>
  );
}