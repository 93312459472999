import React, { useContext, useEffect, useState } from 'react';
import { Box, Popover } from '@mui/material';
import axios from '../../../utils/axios';
import { cWarn } from '../../CustomLogger';
import { LoadingBox } from '../../UniversalComponents';
import { FileBlockContext } from './FileBlockContext';



export default function FileThumbBlock(props){

    const context = useContext(FileBlockContext);
    const {
        doc:{
            id:docId=0,
        },
    } = context;
    
    const {
        onClick=null
    } = props;
    const [imageData, setImageData] = useState(null);
    const apiPath = `${process.env.REACT_APP_API_URL}/docthumb/${docId}/thumb.jpg`;
    useEffect(() => {        
        axios.get(apiPath,{
            timeout: 300000,
            withCredentials:true,
            responseType: 'blob'
        }).then((response) => {
            setImageData(URL.createObjectURL(response.data));
        })
        .catch((error) => {
            cWarn(error.message);
        });
        /* eslint-disable */
      }, []);
        /* eslint-enable */


    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    

    return (
        <Box sx={{width:'75px', height:'75px'}} onClick={onClick} onMouseDown={()=>{handlePopoverClose()}}>
            {
                imageData ? 
                    <>
                        <img 
                            aria-owns={open ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                            style={{width:'75px',height:'75px', cursor:'pointer'}} 
                            src={imageData} 
                            
                            
                            alt="" />
                        <Popover
                            id="mouse-over-popover"
                            sx={{
                            pointerEvents: 'none',
                            left:100,
                            }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                            horizontal: 'left',
                            }}
                            transformOrigin={{
                            
                            vertical: 'top',
                            horizontal: 'left',
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                        >
                            <img style={{minWidth:350, height:500}} src={imageData} alt="" />
                        </Popover>
                    </>
                    :
                    <Box sx={{width:'75px',height:'75px', overflow:'hidden'}}><LoadingBox/></Box>
                }
        </Box>
    );

}