import React, { useContext } from 'react';
import Navigation from './Navigation';
import { Helmet } from 'react-helmet-async';
import { BrandContext } from '../contexts/BrandContext';


export default function Page(props) {

    const {
        title=''
    } = props;
    const brandContext = useContext(BrandContext);

    const {isLoading:isBrandLoading} = brandContext;


    return (
        <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <div>
            {!isBrandLoading &&
            <Navigation/> 
            }
            <div>
                {props.children}
            </div>
        </div>
        </>
    );
}

