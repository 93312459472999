import React, { useContext } from "react";
import { BrandContext } from "../../contexts/BrandContext";
import { Box, Button, Dialog, DialogActions, DialogTitle, Stack } from "@mui/material";

export default function ExistingContactDialogue(props){

    const {
        showDialogue,
        setShowDialogue,
        existingContacts,
        contactIndex,
        formikProps
    } = props;

    const {
        textContent:{
            contactExistsTitle:title='',
            cancel='',
            useContactWithNewData='',
            overWriteWithExistingData=''
        }
    } = useContext(BrandContext);

    const useNewData = () => {
        setFieldsAndClose(true);
    }

    const overWrite = () => {
        setFieldsAndClose(true);
    }

    const setFieldsAndClose = (onlyBlankFields) => {
        //set the fields and close the dialog
        const contact = existingContacts[contactIndex];
        const map = {
            cTitle:'cTitle',
            firstName:'firstName',
            middleName:'middleName',
            lastName:'lastName',
            tel1:'tel1',
            mobile:'mobile',
            houseName:'houseNo',
            houseNumber:'houseNo',
            postcode:'postcode',
            street:'streetName',
            town:'town',
        }
        const keys = Object.keys(map);
        const values = Object.values(map);
        for(let i=0; i<values.length; i+=1){
            if(contact[keys[i]]){
                const fieldName = `c_${values[i]}_${contactIndex}`;
                let saveField = true;
                if(onlyBlankFields){
                    if(formikProps.values[fieldName]){
                        saveField = false;
                    }
                }
                if(saveField){
                    formikProps.setFieldValue(fieldName,contact[keys[i]]);
                }
            }
        }
        setShowDialogue(false);
    }

    return(
        <Dialog open={showDialogue} onClose={()=>{setShowDialogue(false)}} fullWidth className="brandedDialogue">
            <DialogTitle >{title}</DialogTitle>
             <Box sx={{padding:2}}>
                <Stack spacing={2} alignItems="center">
                    <Button variant="outlined" sx={{width:'60%'}} className="roundButton" onClick={useNewData}>{useContactWithNewData}</Button>
                    <Button variant="outlined" sx={{width:'60%'}} className="roundButton blueButton" onClick={overWrite}>{overWriteWithExistingData}</Button>
                    <Button variant="outlined" sx={{width:'60%'}} className="roundButton whiteButton" onClick={()=>{setShowDialogue(false)}} >{cancel}</Button>
                </Stack>
            </Box>
        </Dialog>
    );



}