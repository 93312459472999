import React, { useContext } from 'react';
import { BrandContext } from '../../../contexts/BrandContext';
import { Container } from '@mui/material';
import Page from '../../Page';
import Header from '../../ReportViews/Matters/MattersHeader';
import CreateMatterContextProvider from './CreateMatterContext';
import CreateMatterInner from './CreateMatterInner';

export default function CreateValuation(props) {
     
    const brandContext = useContext(BrandContext);

    const {
        textContent:{
            newMatterPageTitle:pageTitle='',
        }
    } = brandContext;

   

    return (
        <>
            <Page title={pageTitle}>
                <Container>
                <CreateMatterContextProvider>
                        <Header title={pageTitle}/>
                        <CreateMatterInner/>
                    </CreateMatterContextProvider>
                </Container>
            </Page>
        </>
    );
}

