import React, { useContext, useEffect, useState } from 'react';
import { Alert, Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Snackbar, Stack } from "@mui/material";
import { BrandContext } from '../../contexts/BrandContext';
import { Formik } from 'formik';
import axios from '../../utils/axios';
import { cWarn } from '../CustomLogger';
import BasicInputField from '../form/BasicInputField';
import { LoadingBox, getValidationFromFields, initialValues } from '../UniversalComponents';
import { DragContext } from '../Widgets/DragContext';
import { sub } from 'date-fns';
import { faGaugeSimpleMed } from '@fortawesome/free-solid-svg-icons';



export default function SendEmailDialogue(props){
    
    const {
        context,
        matterId
    } = props;
    const flag = 'showSendEmailDialogue';
    const {
        [flag]:showState=false,
        accessScope,
        digiMessageId:msgId=0,
        msgMode='compose',
        emailComposeData=null
    } = context;

    const dragContext = useContext(DragContext);
    const [messageBox, setMessageBox] = useState({
        show:false,
        message:''
    });
    const {
        notifyDrop
    } = dragContext;

    
    const hideDialogue = () => {
        context.setState({
            emailComposeData:null,
            msgMode:'compose',
            digiMessageId:0,
            [flag]:false
        });
    }

    const DialogueInner = () => {

        const brandContext = useContext(BrandContext);        

        const  {
            textContent:{
                sendEmailTitle:title = '',
                sendEmail='',
                saveDraft='',
                cancel=''
            }
        } = brandContext;

       
        
        const [formFields,setFormFields] = useState({});
        const [templateContentLoaded,setTemplateContentLoaded] = useState(false);

        const [isLoaded,setIsLoaded] = useState(false);
        const [showForm,setShowForm] = useState(true);

        const handleSave = (values,isDraft) => {
            const apiPath = `${process.env.REACT_APP_API_URL}/sendemail/${matterId}/${accessScope}`;
            values.isDraft = isDraft;

            const body = new FormData();
            if(values.fileAttachment instanceof FileList){
                const filesList = Array.from(values.fileAttachment);
                for(let i=0; i<filesList.length; i+=1){
                    body.append(`fileAttachment_${i}`, filesList[i]);
                }
                values.fileAttachment = null;
            }
            
            body.append('json', JSON.stringify(values));

            setShowForm(false);  
            axios.post(apiPath,body,{
                headers: {
                    'Content-Type': 'multipart/form-data' // Make sure to set the content type
                },
                timeout: 300000,
                withCredentials:true
            }).then((res) => {
                const {
                    data:{
                        message=''
                    }
                } = res;
                setMessageBox({
                    show:true,
                    message
                });
                                  
                setTimeout(()=>{
                    hideDialogue(flag);  
                    setMessageBox({
                        show:false,
                        message:''
                    });
                },4000);
    
            }).catch(err => {
                cWarn(err); 
            });
            
        }

        useEffect(() => {
            // Update the document title using the browser API
            console.log(emailComposeData);
            if(emailComposeData){
                console.log(emailComposeData);
            }

            if(!isLoaded){
                let apiPath = `${process.env.REACT_APP_API_URL}/getemailfields/${matterId}/${accessScope}`;
                if(msgMode === 'reply'){
                    apiPath = `${process.env.REACT_APP_API_URL}/getemailfields/${matterId}/${accessScope}/reply/${msgId}`;
                }
                else if(msgMode === 'forward'){
                    apiPath = `${process.env.REACT_APP_API_URL}/getemailfields/${matterId}/${accessScope}/forward/${msgId}`;
                }
                
                if(emailComposeData){
                    //
                    axios.post(apiPath,emailComposeData,{
                        timeout: 300000,
                        withCredentials:true
                    }).then(res => {
    
                        const {
                            data:{
                                fields:dataFormFields={}
                            }
                        } = res;
                        setShowForm(true);
                        setFormFields(dataFormFields)
                        setIsLoaded(true);

                        const {tId=null} = emailComposeData;
                        if(tId){
                            setTemplateContentLoaded(true);
                        }
                        
                    }).catch(err => {
                        cWarn(err); 
                    });
                }
                else{
                    axios.get(apiPath,{
                        timeout: 300000,
                        withCredentials:true
                    }).then(res => {
    
                        const {
                            data:{
                                fields:dataFormFields={}
                            }
                        } = res;
                        setShowForm(true);
                        setFormFields(dataFormFields)
                        setIsLoaded(true);
                        
    
                    }).catch(err => {
                    cWarn(err); 
                    });
                }

                

            }
        },[setIsLoaded,isLoaded]);

        

        const setFileName = (e,props)=>{
            const options = formFields.styles.options;
                                                  
            const val = e.target.value;
            for(let i=0; i<options.length; i+=1){
                const {
                    value,
                    label
                } = options[i];
                console.log(`${label} - ${value} :: ${val}`);
                if(value === val){
                    props.setFieldValue('docName',label)
                    break;
                }
            }
        }

        const runTemplates = (e) => {

            const {
                target:{
                    value
                }
            }  =  e;          

            // send the email to the api and get the updated available templates
            const apiPath = `${process.env.REACT_APP_API_URL}/getemailfields/${matterId}/${accessScope}/gettemplates`;
            axios.post(apiPath,{
                'to':value
            },{
                timeout: 300000,
                withCredentials:true
            }).then((res) => {
                const templateOptions = [];
                const {
                    data:{
                        templates
                    }
                } = res;
                for(let i=0; i<templates.length; i+=1){
                    templateOptions.push({
                        label:templates[i].templateName,
                        value:templates[i].id
                    })
                }


                // update the fomrvields value
                setFormFields(prevState => ({
                    ...prevState, // Spread the previous state to keep other properties unchanged
                    templates: {
                      ...prevState.templates, // Spread the previous 'templates' object
                      options: templateOptions
                    }
                  }));
            }).catch(err => {
                cWarn(err); 
            });
        }

        const runEmailContent = (formik,e) => {
            const {
                value
            }  =  e;    
            setTemplateContentLoaded(false);
            // send the email to the api and get the updated available templates
            const apiPath = `${process.env.REACT_APP_API_URL}/getemailfields/${matterId}/${accessScope}/gettemplatecontent`;
            axios.post(apiPath,{
                'templateId':value
            },{
                timeout: 300000,
                withCredentials:true
            }).then((res) => {
                const templateOptions = [];
                const {
                    data:{
                        subject='',
                        content:preamble='',
                        attachments=[]
                    }
                } = res;

                formik.setFieldValue('subject',subject);
                formik.setFieldValue('preamble',preamble);
                // formik.setFieldValue('attachments',attachments);

                const attachmentsFieldOptions = formFields.attachments.options;
                const attachmentValues = [];
                for(let i=0; i<attachmentsFieldOptions.length; i+=1){
                    const option = attachmentsFieldOptions[i];
                    if(attachments.includes(option.value)){
                        attachmentValues.push(option);
                    }
                }
                console.log(attachmentValues);
                formik.setFieldValue('attachments',attachmentValues);

                setTemplateContentLoaded(true);

            }).catch(err => {
                cWarn(err); 
            });
        }
        
        if(isLoaded){
        console.log(formFields.templates.options,msgMode);
        }
        
        return(
            <>
            <DialogTitle>{title}</DialogTitle>
            <Collapse in={isLoaded}>
                {(isLoaded && showForm) ?
                    <Formik
                        initialValues={initialValues(formFields)}
                        validationSchema={getValidationFromFields(formFields)}
                        onSubmit={(values)=>{
                            handleSave(values,false);
                        }}
                    >
                        {props => (
                            <>
                                <DialogContent>
                                        <form onSubmit={props.handleSubmit}>
                                            <Grid container >
                                                <Grid item xs={12} md={12} className="inputHolder">
                                                    <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="fromField" variant="outlined" />  
                                                </Grid>
                                                <Grid item xs={12} md={12} className="inputHolder">
                                                    <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="toField" variant="outlined" onChange={runTemplates} />  
                                                </Grid>
                                                <Grid item xs={12} md={12} className="inputHolder">
                                                    <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="ccField" variant="outlined" onChange={runTemplates}/>  
                                                </Grid>
                                                {((formFields.templates.options.length > 0) && msgMode === 'compose')  && 
                                                <Grid item xs={12} md={12} className="inputHolder">
                                                    <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="templates" variant="outlined" onChange={(e)=>{runEmailContent(props,e)}}/>  
                                                </Grid>
                                                }
                                                {(templateContentLoaded || (msgMode !== 'compose')) && 
                                                <>
                                                    <Grid item xs={12} md={12} className="inputHolder">
                                                        <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="subject" variant="outlined"/>  
                                                    </Grid>
                                                    <Grid item xs={12} md={12} className="inputHolder">
                                                        <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="preamble" variant="outlined"/>  
                                                    </Grid>
                                                    <Grid item xs={12} md={12} className="inputHolder">
                                                        <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="attachments" variant="outlined"/>  
                                                    </Grid>
                                                    <Grid item xs={12} md={12} className="inputHolder">
                                                        <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="fileAttachment" variant="outlined"/>  
                                                    </Grid>
                                                </>
                                                }
                                            </Grid>
                                        </form>                
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={()=>{
                                         handleSave(props.values,true);
                                    }}>{saveDraft}</Button>
                                    <Button onClick={props.handleSubmit}>{sendEmail}</Button>
                                    <Button onClick={()=>{hideDialogue(flag)}}>{cancel}</Button>
                                </DialogActions>
                            </>
                        )}
                    </Formik>
                    :
                    <LoadingBox/>
                }
            </Collapse>
            </>
        );
    }

    const handleCloseMessageBox = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setMessageBox({
            show:false,
            message:''
        });
      };
      
    return (
        <>
        <Dialog open={showState && !messageBox.show} onClose={()=>{hideDialogue(flag)}} fullWidth className="brandedDialogue">
            <Box sx={{minWidth:600}}>
            {showState &&
                <DialogueInner/>
            }
            </Box>
        </Dialog>
        <Snackbar open={messageBox.show} autoHideDuration={6000} onClose={handleCloseMessageBox}>
            <Alert
                onClose={handleCloseMessageBox}
                severity="success"
                variant="filled"
                sx={{ width: '100%' }}
                >
                {messageBox.message}
            </Alert>
        </Snackbar>
        </>
    );
}

