import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import ValuationsContextProvider, { ValuationsContext } from "./ValuationsContext";
import ResultsPage from "./ResultsPage";
import { Formik } from 'formik';
import { resFormFields, tableFormIntialValues, tableFormValidationSchema } from "./ValuationsUtilities";
import { cLog } from "../../CustomLogger";

export default function TravelCoDash(){
    const history = useNavigate();
    return (

        <ValuationsContextProvider history={history}>
            <Formik
                initialValues={tableFormIntialValues()}
                validationSchema={tableFormValidationSchema}
                onSubmit={(v)=>{
                    cLog(v)
                }}
            >
                {props => (
                    <>
                    

                    <CheckBoxHandler formik={props}/>
                    <ResultsPage
                        resFormik={props} 
                        resFormFields={resFormFields}
                    />
                    </>
                    )}
            </Formik>
        </ValuationsContextProvider>
    );
}

const CheckBoxHandler = (props) => {

    const {
        formik
    } = props;

    const valuationsContext = useContext(ValuationsContext);
    /* eslint-disable */
    useEffect(() => {
        valuationsContext.setState({
            resSelected:formik.values.resCheckBoxes,
        });
    },[formik.values.resCheckBoxes]);
    /* eslint-enable */
    return null;

}