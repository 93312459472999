import React, { useContext } from "react";
import { Container } from "@mui/material";

import { useFormik } from "formik";


import Page from "../../Page";
import Results from "./Results";
import { MattersContext } from "./MattersContext";
import { getAddressFields, getInitialFieldValues } from "./Handler";
import { BrandContext } from "../../../contexts/BrandContext";
import MattersHeader from "./ContactsHeader";
import { getValidationFromFields } from "../../UniversalComponents";


export default function ResultsPage(){

    const context = useContext(MattersContext);

    const doSearch = () =>{
        formik.handleSubmit();
    }

    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);


    const formFields = getAddressFields(context,doSearch);
    context.setFormFields(formFields);

    const brandContext = useContext(BrandContext);

    const formik = useFormik({
        initialValues: getInitialFieldValues(context,parameters),
        validationSchema: getValidationFromFields(formFields),
        onSubmit: (values) => {
            context.setState({
                searchTerms:values
            });

            context.updateSearch();
        }
    });

    const {
        textContent:{
            valuationsTitle:pageTitle=''
        }
    } = brandContext;

    return (
        <>
        
            <Page title="Admin">
                <MattersHeader title={pageTitle}/>
                <Container maxWidth="xl">

                    <Results formFields={formFields} formik={formik} searchParams={parameters}/>
                    
                </Container>
            </Page>
        </>
        );
    }