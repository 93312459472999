import React, { useContext, useEffect, useState } from 'react';
import { Alert, Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Snackbar, Stack } from "@mui/material";
import { BrandContext } from '../../contexts/BrandContext';
import { Formik } from 'formik';
import axios from '../../utils/axios';
import { cWarn } from '../CustomLogger';
import BasicInputField from '../form/BasicInputField';
import { LoadingBox, getValidationFromFields, initialValues } from '../UniversalComponents';
import { DragContext } from '../Widgets/DragContext';




export default function SaveCommNoteDialogue(props){
    
    const {
        context,
        matterId
    } = props;
    const flag = 'saveCommNoteEmailDialogue';
    const {
        [flag]:showState=false,
        hideDialogue,
        accessScope,
    } = context;

    const dragContext = useContext(DragContext);
    const [messageBox, setMessageBox] = useState({
        show:false,
        message:''
    });
    

    
    

    const DialogueInner = () => {

        const brandContext = useContext(BrandContext);        

        const  {
            textContent:{
                saveFileNoteTitle:title = '',
                save='',
            }
        } = brandContext;

        const [formFields,setFormFields] = useState({});
        const [isLoaded,setIsLoaded] = useState(false);
        const [showForm,setShowForm] = useState(true);

        const handleSave = (values,isDraft) => {
            const apiPath = `${process.env.REACT_APP_API_URL}/savecommnote/${matterId}/${accessScope}`;
            values.isDraft = isDraft;

            const body = new FormData();
            if(values.fileAttachment instanceof FileList){
                const filesList = Array.from(values.fileAttachment);
                for(let i=0; i<filesList.length; i+=1){
                    body.append(`fileAttachment_${i}`, filesList[i]);
                }
            }
            
            Object.keys(values).forEach(key => {
                if (key !== 'fileAttachment') { // Exclude the file from being appended again
                  body.append(key, values[key]);
                }
              });

            setShowForm(false);  
            axios.post(apiPath,body,{
                headers: {
                    'Content-Type': 'multipart/form-data' // Make sure to set the content type
                },
                timeout: 300000,
                withCredentials:true
            }).then((res) => {
                const {
                    data:{
                        message=''
                    }
                } = res;
                setMessageBox({
                    show:true,
                    message
                });
                                  
                setTimeout(()=>{
                    hideDialogue(flag);  
                    setMessageBox({
                        show:false,
                        message:''
                    });
                },4000);
    
            }).catch(err => {
                cWarn(err); 
            });
            
        }

        useEffect(() => {
            // Update the document title using the browser API



            if(!isLoaded){
                let apiPath = `${process.env.REACT_APP_API_URL}/getcommnotefields/${matterId}/${accessScope}`;
              
                axios.get(apiPath,{
                    timeout: 300000,
                    withCredentials:true
                }).then(res => {

                    const {
                        data:{
                            fields:dataFormFields={}
                        }
                    } = res;
                    setShowForm(true);
                    setFormFields(dataFormFields)
                    setIsLoaded(true);
                    

                }).catch(err => {
                cWarn(err); 
                });

            }
        },[setIsLoaded,isLoaded]);

        


     

        return(
            <>
            <DialogTitle>{title}</DialogTitle>
            <Collapse in={isLoaded}>
                {(isLoaded && showForm) ?
                    <Formik
                        initialValues={initialValues(formFields)}
                        validationSchema={getValidationFromFields(formFields)}
                        onSubmit={(values)=>{
                            handleSave(values,false);
                        }}
                    >
                        {props => (
                            <>
                                <DialogContent>
                                        <form onSubmit={props.handleSubmit}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} md={12} className="inputHolder">
                                                    <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="subject" variant="outlined"  />  
                                                </Grid>
                                                <Grid item xs={12} md={12} className="inputHolder">
                                                    <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="preamble" variant="outlined"  />  
                                                </Grid>
                                                <Grid item xs={12} md={12} className="inputHolder">
                                                    <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="noteType" variant="outlined"  />  
                                                </Grid>
                                                <Grid item xs={12} md={6} className="inputHolder">
                                                    <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="issuedOn" variant="outlined" sx={{width:'100%'}} />  
                                                </Grid>
                                                <Grid item xs={12} md={6} className="inputHolder">
                                                    <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="issuedAt" variant="outlined" sx={{width:'100%'}} />  
                                                </Grid>
                                                
                                            </Grid>
                                        </form>                
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={props.handleSubmit}>{save}</Button>
                                </DialogActions>
                            </>
                        )}
                    </Formik>
                    :
                    <LoadingBox/>
                }
            </Collapse>
            </>
        );
    }

    const handleCloseMessageBox = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setMessageBox({
            show:false,
            message:''
        });
      };
      
    return (
        <>
        <Dialog open={showState && !messageBox.show} onClose={()=>{hideDialogue(flag)}} fullWidth className="brandedDialogue">
            <Box sx={{minWidth:600}}>
            {showState &&
                <DialogueInner/>
            }
            </Box>
        </Dialog>
        <Snackbar open={messageBox.show} autoHideDuration={6000} onClose={handleCloseMessageBox}>
            <Alert
                onClose={handleCloseMessageBox}
                severity="success"
                variant="filled"
                sx={{ width: '100%' }}
                >
                {messageBox.message}
            </Alert>
        </Snackbar>
        </>
    );
}

