export const getDefaultMatterName = (matterDetails) => {

    const {
        id,
        client,
        matterType:{
            name:matterTypeName=''
        },
        matterData,
        emailTag=''
        
    } = matterDetails;

    let fullName = '';
    if(client){
        try{
            if('primaryContact' in client){
                ({primaryContact:{fullName}} = client);
            }   
        }
        catch(e){
            // cWarn(e)
        }
    }

    return `${matterTypeName} for ${fullName}`
}