import React, { useContext, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import axios from '../../../utils/axios';
import { cWarn } from '../../CustomLogger';
import { LoadingBox, getValidationFromFields, initialValues } from '../../UniversalComponents';
import { Formik } from 'formik';
import BasicInputField from '../../form/BasicInputField';
import { BrandContext } from '../../../contexts/BrandContext';

export default function AddNewTask(props){
    
    const brandContext = useContext(BrandContext);
    const {
        showDialogue,
        setShowDialogue,
        updateCall=false,
        parentUnit,
        fields=null,
        inboxId=null,
        selectMatter=false
    } = props;
    const {
        textContent:{
            newTaskButton:dialogTitle='',
            cancel='',
            save=''
        }
    } = brandContext;
    
    const [formFields,setFormFields] = useState(fields);

     /* eslint-disable */
     useEffect(() => {
        if(!formFields){
            const apiPath = `${process.env.REACT_APP_API_URL}/tasks/createconfig`;

            axios.get(apiPath,{
                timeout: 300000,
                withCredentials:true
            }).then(res => {

                const {
                    data:{
                        fields=[]
                    }
                } = res;
                fields.matter.optionTemplate =  (option) => {
                    if(option){
                        const {label=''} = option
                        return label;
                    }
                    return ''
                }
                setFormFields(fields);             
            }).catch(err => {
            cWarn(err); 
            });
        }
    },[]);
    /* eslint-enable */

    return (
        <Dialog open={showDialogue} onClose={()=>{setShowDialogue(false)}}>
            <DialogTitle>{dialogTitle}</DialogTitle>
            {!formFields ? 
                <LoadingBox/>
                :
                <Formik
                    initialValues={initialValues(formFields)}
                    validationSchema={getValidationFromFields(formFields)}
                    onSubmit={(values) => {
                        const apiPath = `${process.env.REACT_APP_API_URL}/tasks/add`;

                        if(inboxId){
                            values.inboxId = inboxId;
                        }

                        axios.post(apiPath,values,{
                            timeout: 300000,
                            withCredentials:true
                        }).then((res) => {
                            setShowDialogue(false);
                            if(updateCall){
                                updateCall();
                            }
                        }).catch(err => {
                            cWarn(err);
                        });
                    }}
                >
                    {props => (
                        <form onSubmit={props.handleSubmit}>
                            <DialogContent>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12}>
                                        <BasicInputField  formik={props} formFields={formFields} fieldName="title" variant='outlined'/>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <BasicInputField  formik={props} formFields={formFields} fieldName="description" variant='outlined'/>
                                    </Grid>
                                    {selectMatter &&
                                    <Grid item xs={12} md={12}>
                                        <BasicInputField  formik={props} formFields={formFields} fieldName="matter" variant='outlined'/>
                                    </Grid>    
                                    }
                                    <Grid item xs={12} md={12}>
                                        <BasicInputField  formik={props} formFields={formFields} fieldName="assignee" variant='outlined'/>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <BasicInputField  formik={props} formFields={formFields} fieldName="deadline" variant='outlined'/>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <BasicInputField  formik={props} formFields={formFields} fieldName="hideTime" variant='outlined'/>
                                    </Grid>
                                    {
                                        !props.values.hideTime &&
                                        <Grid item xs={12} md={12}>
                                            <BasicInputField  formik={props} formFields={formFields} fieldName="deadlineTime" variant='outlined'/>
                                        </Grid>
                                    }
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={()=>{setShowDialogue(false)}}>{cancel}</Button>
                                <Button type="submit">{save}</Button>
                            </DialogActions>
                        </form>                                
                    )}
                </Formik>
            }
        </Dialog>
    );
    
}
