import React, {createContext} from 'react';

import axios from 'axios';


export const BrandContext = createContext();

class BrandContextProvider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            hasFailed:false,
            textContent:{},
            textDataLoaded:false,
            brand: {}

        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData(){

        if(this.state.isLoading) {

            
            this.getTextContent();
            const apiPath = `${process.env.REACT_APP_API_URL}/brand`;
            axios.get(apiPath, {
                timeout: 30000,
                withCredentials: true
            }).then(res => {
                this.setState({
                    isLoading: false,
                    hasFailed:false,
                    brand: res.data
                })
            }).catch(err => {
                this.setState({
                    hasFailed:true
                });
                // console.log('caught the error');
                // console.log(err);
            });
            
        }
    }


    getTextContent(){
        const apiPath = `${process.env.REACT_APP_API_URL}/textdata`;
        axios.get(apiPath,{
            timeout: 30000,
            withCredentials:true
        }).then(res => {
            this.setState({
                textContent:res.data,
                textDataLoaded:true
            });
        }).catch(err => {
            // console.log('caught the error');
            // console.log(err);
        });

    };

    render() {
        return (
            <BrandContext.Provider value={{
                ...this.state,
                setState:this.setState.bind(this)
            }}>
                {this.props.children}
            </BrandContext.Provider>
        );
    }
}

export default BrandContextProvider;