import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
// redux
import {getInitialize} from '../../redux/slices/authDal';

// ----------------------------------------------------------------------

DalProvider.propTypes = {
    children: PropTypes.node
};

export default function DalProvider({ children }) {
    const dispatch = useDispatch();



    useEffect(() => {
        dispatch(getInitialize());
    }, [dispatch]);

    return <>{children}</>;
}
