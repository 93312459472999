//REACT
import React, { useContext } from 'react';
//ROUTER
import { BrowserRouter, Route, Routes } from "react-router-dom";

// guards
import AuthGuard from '../guards/AuthGuard';

//MUI COMPONENTS
//CUSTOM COMPONENTS
import { BrandContext } from '../contexts/BrandContext';
import { navConfig } from '../NavConfig';
import Lost from './pages/Lost';






const DalRouter = () => {

    
    const brandContext = useContext(BrandContext);

    const {
        isLoading,
        brand:{
            mode
        }
    } = brandContext;

    const routes = [];
    if(!isLoading){
        for(let i=0; i < navConfig[mode].length; i+=1){
            const{
                subNav=[],
            } = navConfig[mode][i];

            routes.push(navConfig[mode][i]);

            for(let j=0; j<subNav.length; j+=1){
                routes.push(subNav[j]);
            }
                    
        }
    }




    if(brandContext.textDataLoaded){    
        
        return ( 
        <AuthGuard>
            <BrowserRouter>
                <Routes>
                    {routes.map((link,index)=>{
                        const{
                            path,
                            componemt:Component,
                            exact=true
                        } = link;
                        if(Component){
                            return (
                                <Route key={index} exact={exact} path={path}  element={<Component/>}/>
                            );
                        }
                        return null;
                    })}
                    <Route path="*"  element={<Lost/>}/>
                </Routes>                      
            </BrowserRouter>
        </AuthGuard>
        );
    }
    return null;
    
};

export default DalRouter;