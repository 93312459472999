import React, { useContext } from 'react';

import { Helmet } from "react-helmet-async";
import BDAuth from './BDAuth';
import { BrandContext } from '../contexts/BrandContext';

function SignIn(props) {

    const brandContext = useContext(BrandContext);

    const {
        textContent:{
            loginTitle:pageTitle=''
        }
    } = brandContext;


    const {isLoading:isBrandLoading} = brandContext;




    return (
        <>
        <Helmet>
            <html lang="en" />
            <title>{pageTitle}</title>
            <style>{`body { background-color: #222546; height:100%}`}</style>
        </Helmet>

        <div className="loginFullOuter">
            {!isBrandLoading &&
            <div className="login-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '800px' }}>
                <div className="login-box" style={{ width: '500px', borderRadius: '10px' }}>
                    <BDAuth/>
                </div>
            </div>
            }
        </div>
        </>
    );
    
}

export default SignIn;