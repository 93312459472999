import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import settingsReducer from './slices/settings';
import authDalReducer from './slices/authDal';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['settings']
};


const authPersistConfig = {
  key: 'authDal',
  storage,
  keyPrefix: 'redux-',
  // whitelist: ['isAuthenticated']
};

const rootReducer = combineReducers({
  settings: settingsReducer,
  authDal: persistReducer(authPersistConfig, authDalReducer)
});

export { rootPersistConfig, rootReducer };
