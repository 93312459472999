import React, { useContext, useEffect, useState } from "react";
import axios from '../../../utils/axios';
import { cWarn } from "../../CustomLogger";
import { Box, Typography } from "@mui/material";
import { Button, DialogActions, DialogContent, Grid } from '@mui/material';
import BasicInputField from '../../form/BasicInputField';
import { Formik } from "formik";
import { format } from 'date-fns';
import { LoadingBox, getValidationFromFields, initialValues } from '../../UniversalComponents';
import { BrandContext } from "../../../contexts/BrandContext";
import { useParams } from "react-router-dom";
import { FaPaperclip } from "react-icons/fa";
import { Link } from "react-router-dom";
import FileBlockContextProvider from "../../Utilities/Documents/FileBlockContext";
import DraggableBlock from "../../Utilities/Documents/DraggableBlock";
import FileThumbWithDialogue from "../../Utilities/Documents/FileThumbWithDialogue";
import { getDefaultMatterName } from "../../ReportViews/Matters/MatterUtilities";


export default function OpenTask(){

    const brandContext = useContext(BrandContext);

    const [isLoaded,setIsLoaded] = useState(false);
    const [formFields,setFormFields] = useState([]);
    const [taskDetails,setTaskDetails] = useState(null);
    const [markingComplete,setMarkingComplete] = useState(false)
    const {task} = useParams();


    const {
        textContent:{
            cancel='',
            save='',
            completeTaskButton='',
            fromLabel='',
            toLabel='',
            linkedEmail='',
            checklistLabel=''
        }
    } = brandContext;

    /* eslint-disable */
    useEffect(() => {
        
        if(!isLoaded){
            // Update the document title using the browser API
            loadData();
        }
    },[isLoaded]);
    /* eslint-enable */

    const loadData = () => {
        

        if(task){
            const apiPath = `${process.env.REACT_APP_API_URL}/tasks/${task}/editconfig`;
            axios.get(apiPath,{
                timeout: 300000,
                withCredentials:true
            }).then(res => {

                const {
                    data:{
                        fields=[],
                        task
                    }
                } = res;
                setFormFields(fields);
                setTaskDetails(task);
                setIsLoaded(true);
            }).catch(err => {
                cWarn(err); 
            });
        }
    }

    const closeMe = () => {
         window.close();
    }

    const InnerForm = () => {
        
        return (
            <Box>
                 {taskDetails && <TaskDetails/>}
                <Formik
                        initialValues={initialValues(formFields)}
                        validationSchema={getValidationFromFields(formFields)}
                        onSubmit={(values) => {
                            
                            const apiPath = `${process.env.REACT_APP_API_URL}/tasks/edit/${task}`;
                            axios.post(apiPath,values,{
                                timeout: 300000,
                                withCredentials:true
                            }).then((res) => {
                                closeMe();
                            }).catch(err => {
                                cWarn(err);
                            });
                        }}
                    >
                        {props => (
                            <form onSubmit={props.handleSubmit}>
                                <DialogContent>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={12}>
                                            <BasicInputField  formik={props} formFields={formFields} fieldName="title" variant='outlined'/>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <BasicInputField  formik={props} formFields={formFields} fieldName="description" variant='outlined'/>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <BasicInputField  formik={props} formFields={formFields} fieldName="assignee" variant='outlined'/>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <BasicInputField  formik={props} formFields={formFields} fieldName="deadline" variant='outlined'/>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <BasicInputField  formik={props} formFields={formFields} fieldName="hideTime" variant='outlined'/>
                                        </Grid>
                                        {
                                            !props.values.hideTime &&
                                            <Grid item xs={12} md={12}>
                                                <BasicInputField  formik={props} formFields={formFields} fieldName="deadlineTime" variant='outlined'/>
                                            </Grid>
                                        }
                                    </Grid>
                                    
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={()=>{closeMe()}}>{cancel}</Button>
                                    <Button onClick={()=>{
                                        props.values.markComplete = false;
                                        props.handleSubmit();
                                    }}>{save}</Button>
                                    <Button onClick={()=>{
                                        props.values.markComplete = true;
                                        props.handleSubmit();
                                    }}>{completeTaskButton}</Button>
                                </DialogActions>
                            </form>                                
                        )}
                    </Formik>
                </Box>
            );
    }

    const TaskDetails = () => {
        const {
            description='',
            commMessage=null,
            matter=null,
            checklistItem=null,
        } = taskDetails;
        return (
            <Box sx={{paddingTop:'20px',paddingLeft:'20px',paddingRight:'20px'}}>
                <Typography variant="h2">{description}</Typography>
                {matter && 
                    <MatterDetail matter={matter}/>
                }
                {commMessage && 
                    <CommMessageDetail commMessage={commMessage}/>
                }
                {checklistItem && 
                    <ChecklistItemDetail checklistItem={checklistItem}/>
                }
            </Box>
        );
    }

    const ChecklistItemDetail = (props) => {
        const {
            checklistItem:{
                id,
                ftaskName:taskName,
                fTriggerDate:days,
                matterChecklistItems=[]
            }
        } = props;

        return (
            <Box>
                {checklistLabel} {taskName}
            </Box>
        );
    }

    const MatterDetail = (props) => {
        const {
            matter:{
                id,
                client,
                matterData,
                matterType:{
                    name:matterTypeName=''
                },
                matterUserRoles=[]
            },
            matter
        } = props;
        let matterName = '';
        if(matterData){
            ({matterName} = matterData);
        } 
        if(matterName === '' || matterName === null){
            matterName = getDefaultMatterName(matter);
        }
        return (
            <Box sx={{marginLeft:'-6px'}}>
                <Button 
                    target="_blank"
                    rel="noopener noreferrer"
                    component={Link} 
                    to={`/matter/${id}`}>
                            {id} - {matterName}
                </Button>
            </Box>
        );
    }

    const CommMessageDetail = (props) => {
        const {
            commMessage:{
                id:messageId,
                commEmail:{
                    id:emailId,
                    subject='',
                    textPlain='',
                    textHtml = '',
                    contacts=[],
                    attachments=[]
                },
                matterComm:{
                    commDirection:{
                        code:directionFlag='INCOMING'
                    }
                },
                issuedAt
            },
        } = props;


        let fromContacts=[];
        let toContacts=[];
        let ccContacts=[];
        for(let i=0; i<contacts.length; i+=1){
            const {
                contactType:{
                    code='FROM',
                },
                displayName='',
                emailAddress=''
            } = contacts[i];
            let contactLabel = ''
            if(displayName){
                if(displayName !== emailAddress){
                    contactLabel = `${displayName} <${emailAddress}>`;
                }
            }
            if(contactLabel === ''){
                contactLabel = emailAddress;
            }
    
            if(code === 'FROM'){
                fromContacts.push(contactLabel);
            }
            if(code === 'TO'){
                toContacts.push(contactLabel);
            }
            if(code === 'CC'){
                ccContacts.push(contactLabel);
            }
        }
        const fromContact = fromContacts.join(', ');
        const toContact = toContacts.join(', ');
        const ccContact = ccContacts.join(', ');

        const emailDate = format(new Date(issuedAt), 'do MMM yyyy HH:mm', { useAdditionalWeekYearTokens: true });

        return (
            <Box sx={{marginTop:'5px'}}>
                <Typography variant="body1Bold" sx={{flexGrow:1, paddingTop:'10px'}}>
                        {linkedEmail} 
                        {directionFlag === 'INCOMING' ? 
                            ` ${fromLabel} ${fromContact}, ${toLabel} ${toContact}`
                            :
                            ` ${toLabel} ${toContact}, ${fromLabel} ${fromContact}`
                        }
                        { ccContact && 
                            <><br/>CC: {ccContact}</>
                        }
                    </Typography>
                    <Typography className="emailDate" sx={{paddingTop:'2px'}}>
                        {emailDate}
                    </Typography>
                    {attachments.length > 0 &&
                            <Box className="attachmentsContainer">
                                {attachments.map((attachment,index)=>{
                                    const {
                                        doc:{
                                            docName,
                                            id:docId
                                        },
                                        doc 
                                    } = attachment;
                                    return (
                                        <Box key={index}>
                                        <FileBlockContextProvider key={index} doc={doc} sendToIcon sendToSortingOffice>
                                                <DraggableBlock >
                                                    <FileThumbWithDialogue />
                                                </DraggableBlock>
                                            </FileBlockContextProvider>
                                        </Box>
                                    );
                                })}
                            </Box>
                        }
            </Box>
        )   
    }

    return (
        <Box>
            {isLoaded ? 
                <>
                    <InnerForm/>
                </>
                :
                <LoadingBox/>
            }
        </Box>
    );



}