import React, { useContext, useEffect, useState } from "react";
import { BrandContext } from "../../../contexts/BrandContext";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { Formik } from 'formik';
import axios from '../../../utils/axios';
import BasicInputField from "../../form/BasicInputField";
import { cWarn } from "../../CustomLogger";
import { getValidationFromFields, initialValues, LoadingBox } from "../../UniversalComponents";


export default function MatterTypeDialogue(props){

    const brandContext = useContext(BrandContext);

    const {
        textContent:{
            fieldsMatterType:dialogueTitle='',
            save='',
            cancel=''
        }
    } =  brandContext;

    const {
        showDialogue,
        setShowDialogue,  
        context:{
            loadData,
            matterId,
            details:{
                matterType:{
                    name:matterTypeName='',
                    id:matterTypeId=0
                },
            },
        },     
    } = props;


    const [matterTypeOptions,setMatterTypeOptions] = useState([]);
    const [loaded,setLoaded] = useState(false);

    /* eslint-disable */
    useEffect(() => {
        const apiPath = `${process.env.REACT_APP_API_URL}/getmattertypes/`;

        axios.get(apiPath,{
            timeout: 300000,
            withCredentials:true
        }).then(res => {

            const {
                data:{
                    matterTypes
                }
            } = res;

            setMatterTypeOptions(matterTypes);
            setLoaded(true);
        }).catch(err => {
            cWarn(err);
        });
    },[]);
    /* eslint-enable */


    const formFields = {
        matterType:{
            label:'fieldsMatterType',
            default:matterTypeId,
            type:'select',
            options:matterTypeOptions,
            required:false
        }
    };



    return(
        <Dialog open={showDialogue} onClose={()=>{setShowDialogue(false)}} fullWidth className="brandedDialogue">
            <DialogTitle>
                {dialogueTitle}
            </DialogTitle>
            <Formik
                initialValues={initialValues(formFields)}
                validationSchema={getValidationFromFields(formFields)}
                onSubmit={(values)=>{
                    const apiPath = `${process.env.REACT_APP_API_URL}/setmattertype/${matterId}`;
                    axios.post(apiPath,values,{
                        timeout: 300000,
                        withCredentials:true
                    }).then((res) => {
                        const {
                            data:{
                                success=false
                            }
                        } = res;
                        if(success){
                            setShowDialogue(false)
                            loadData();
                        }
                        else{
                            cWarn('There was a problem saving the form');
                        }
                        
                    }).catch(err => {
                        cWarn(err); 
                    });
                }}
            >
                {props => (
                    <>
                    {loaded ? 
                    <>
                        <DialogContent>
                            <form onSubmit={props.handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12} className="inputHolder">
                                        <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName={`matterType`} variant="outlined" sx={{width:'100%'}}/>  
                                    </Grid>
                                </Grid>
                            </form>     
                        </DialogContent>
                        <DialogActions>
                        <Button variant="outlined" onClick={()=>{setShowDialogue(false)}}>{cancel}</Button>
                            <Button variant="outlined" onClick={props.handleSubmit}>{save}</Button>
                        </DialogActions>
                    </>
                    :
                    <LoadingBox/>
                    }
                    </>
                )}
            </Formik>
        </Dialog>
    );

}