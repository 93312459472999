import React, { createContext } from 'react';
import { cWarn } from '../CustomLogger';
import axios from '../../utils/axios';

export const DragContext = createContext();

class DragContextProvider extends React.Component {
    constructor(props){
        super(props);

        

        const obj = {
            handlingDrop:0,
            isDragging:false
        };
        this.state = obj;
    }


    handleDragStart(){
        this.setState({
            isDragging:true
        });
    }

    notifyDrop = () => {
        
        this.setState((prevState) => {
            return{ 
             handlingDrop:prevState.handlingDrop + 1
            }
         })
    }

    handleDrop(e){
        let docCatId = 0;
        console.log(e);
        const {
            active:{
                id:docId=0
            },
            over=null
        } = e;
        if(over){
            ({id:docCatId} = over);
        }
        
        if(docId && docCatId){
            //we have a drop on a document category, update it
            const apiPath = `${process.env.REACT_APP_API_URL}/doccatupdate`;
            // Make a POST request to your API using Axios
            axios.post(apiPath, {
                docId,
                docCatId
            })
            .then(() => {
                // Handle the response from your API, e.g., store the uploaded files in state
                this.notifyDrop();
            })
            .catch((error) => {
                // Handle errors, e.g., display an error message
                cWarn(error);
            });
        }
        
        this.setState({
            isDragging:false
        });
    }

    render(){
        return (
            <DragContext.Provider value={{
                ...this.state,
                setState: this.setState.bind(this),
                handleDrop: this.handleDrop.bind(this),
                handleDragStart: this.handleDragStart.bind(this),
                notifyDrop:this.notifyDrop.bind(this),
            }}>
                {this.props.children}
            </DragContext.Provider>
        );
    }

}
export default DragContextProvider;