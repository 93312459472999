import * as Yup from 'yup';

export const resFormFields = {
    'resCheckBoxes': {
        'label': 'checlistBoxLabel',
        'type':'multiCheckboxItem',
    },
    

}

export const tableFormValidationSchema = () => {
    const validationSchema = Yup.object().shape({

    });
    return validationSchema;
}

export const tableFormIntialValues = () => {
    const fields = resFormFields;

    const initialValues = {};
    const keys = Object.keys(fields);
    const values = Object.values(fields);
    for (let i = 0; i < keys.length; i += 1) {
        initialValues[keys[i]] = ('default' in values[i] ? values[i].default : '');
    }
    return initialValues;
}