import React, { useContext } from "react";
import { Container } from "@mui/material";

import { useFormik } from "formik";


import Page from "../../Page";
import Results from "./Results";
import { ValuationsContext } from "./ValuationsContext";
import { getAddressFields, getFieldValidationSchema, getInitialFieldValues } from "./Handler";
import { BrandContext } from "../../../contexts/BrandContext";
import ValuationsHeader from "./ValuationsHeader";
import DeleteDialogue from "./DeleteDialogue";


export default function ResultsPage(props){

    const context = useContext(ValuationsContext);

    const doSearch = () =>{
        formik.handleSubmit();
    }

    const {
        resFormik,
        resFormFields
    } = props;

    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);

    const {
        loadData,
        showDeleteDialogue,
        setShowDeleteDialogue
    } = context;


    const formFields = getAddressFields(context,doSearch);
    context.setFormFields(formFields);

    const brandContext = useContext(BrandContext);

    const formik = useFormik({
        initialValues: getInitialFieldValues(context,parameters),
        validationSchema: getFieldValidationSchema,
        onSubmit: (values) => {
            context.setState({
                searchTerms:values
            });
            
            context.updateSearch();
        }
    });

    const {
        textContent:{
            valuationsTitle:pageTitle=''
        }
    } = brandContext;

    return (
        <>
        
            <Page title="Admin">
                <Container maxWidth="xl">
                    <ValuationsHeader title={pageTitle}/>
                    <Results resFormik={resFormik} resFormFields={resFormFields} formFields={formFields} formik={formik} searchParams={parameters}/>
                    <DeleteDialogue
                        showDialogue={showDeleteDialogue}
                        setShowDialogue={setShowDeleteDialogue}
                        updateCall={()=>{
                            loadData();
                        }}
                    />
                </Container>
            </Page>
        </>
        );
    }