import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import axios from '../../../utils/axios';

import { FileBlockContext } from './FileBlockContext';
import { cWarn } from '../../CustomLogger';
import { Formik } from 'formik';
import { getValidationFromFields, initialValues } from '../../UniversalComponents';
import BasicInputField from '../../form/BasicInputField';
import { BrandContext } from '../../../contexts/BrandContext';
import { MatterDetailsContext } from '../../pages/matters/MatterDetailsContext';
import { DragContext } from '../../Widgets/DragContext';

export default function SendToDocCatDialogue(props){

    const context = useContext(FileBlockContext);
    const brandContext = useContext(BrandContext);
    
    const matterContext = useContext(MatterDetailsContext);
    const dragContext = useContext(DragContext);
    const {handlingDrop,notifyDrop} = dragContext;
    const {
        details:{
            id:matterId,
        },
        accessScope
    } = matterContext;

    const {
        textContent:{
            sendToIconButton=''
        }
    } = brandContext;

    const {
        doc:{
            id:docId=0,
        },
    } = context;
    
    const {
        showDialogue,
        setShowDialogue,
    } = props;
    

    const [isLoaded,setIsLoaded] = useState(false);

    const[docCatOptions,setDocCatOptions] = useState([]);

    /* eslint-disable */
    useEffect(() => {
        // Update the document title using the browser API
        if(!isLoaded){
            const apiPath = `${process.env.REACT_APP_API_URL}/getdoccats/${matterId}/${accessScope}`;

            axios.get(apiPath,{
                timeout: 300000,
                withCredentials:true
            }).then(res => {
                const {
                        data:{
                            options:docCatOptions = []
                        }
                } = res;                  
                setIsLoaded(true);
                setDocCatOptions(docCatOptions);
            }).catch(err => {
                cWarn(err); 
            });

        }
    },[isLoaded]);
    /* eslint-enable */


    const formFields = {
        'docCat':{
            'label':'docCatLabel',
            'type':'select',
            'options':docCatOptions
        }
    };

    return (
        <Dialog open={showDialogue} onClose={()=>{setShowDialogue(false)}} fullWidth>
            <DialogTitle>{sendToIconButton}</DialogTitle>
            <DialogContent>
                <Box >
                <Formik
                    initialValues={initialValues(formFields)}
                    validationSchema={getValidationFromFields(formFields)}
                    onSubmit={(values)=>{
                        const apiPath = `${process.env.REACT_APP_API_URL}/doccatupdate`;
                        // Make a POST request to your API using Axios
                        axios.post(apiPath, {
                            docId,
                            docCatId:values.docCat
                        })
                        .then(() => {
                            // Handle the response from your API, e.g., store the uploaded files in state
                            setShowDialogue(false);
                            notifyDrop();
                        })
                        .catch((error) => {
                            // Handle errors, e.g., display an error message
                            cWarn(error);
                        });
                    }}
                >
                    {props => (
                        <>
                            <form onSubmit={props.handleSubmit}>
                                <Grid container >
                                    <Grid item xs={12} md={12} className="inputHolder">
                                        <BasicInputField autoComplete='off' autoFocus={false} className="cardInput" formik={props} formFields={formFields} fieldName="docCat" variant="outlined" sx={{width:400}}/>  
                                    </Grid>
                                </Grid>
                            </form>     
                            <Button variant="outlined" onClick={props.handleSubmit}>{sendToIconButton}</Button>
                        </>
                    )}
                </Formik>
                </Box>
                
            </DialogContent>
            <DialogActions>
                
            </DialogActions>
        </Dialog>
    );

}
