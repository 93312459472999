import React, { useContext, useState } from 'react';
import { BrandContext } from '../../../contexts/BrandContext';
import { LoadingBox } from '../../UniversalComponents';
import { MatterDetailsContext } from './MatterDetailsContext';
import { Backdrop, Box, Button, CircularProgress, Paper, Typography } from '@mui/material';
import { leftWidgets, tabs, widgetMap } from './WidgetConfig';
import TabsContainer from '../../Widgets/TabsContainer';
import CreateLetterDialogue from '../../Utilities/CreateLetterDialogue';
import CreateDocumentDialogue from '../../Utilities/CreateDocumentDialogue';
import TargetDateDialogue from '../../Utilities/TargetDateDialogue';

import ArchiveMatterDialogue from '../../Utilities/ArchiveMatterDialogue';
import SendEmailDialogue from '../../Utilities/SendEmailDialogue';
import axios from '../../../utils/axios';
import { cLog, cWarn } from '../../CustomLogger';
import SaveCommNoteDialogue from '../../Utilities/SaveCommNoteDialogue';
import MatterNameDialogue from '../../ReportViews/Matters/MatterNameDialogue';
import { getDefaultMatterName } from '../../ReportViews/Matters/MatterUtilities';
import RelatedTransactions from '../../Utilities/RelatedTransactions';
import { WidgetsContext } from '../pcdashboard/WidgetsContext';
import DeceasedDateDialogue from '../../Utilities/DeceasedDateDialogue';
import ErrorsWarningDialogue from '../../Utilities/ErrorsWarningDialogue';
import MatterTypeDialogue from '../../ReportViews/Matters/MatterTypeDialogue';

export default function MatterDetailsInner(props) {
     
    const brandContext = useContext(BrandContext);

    const context = useContext(MatterDetailsContext); 
    const widgetsContext = useContext(WidgetsContext);

    let showPropelTasks = true;

    const{
        isLoading,
        loadData
    } = context;
    const [showTargetDateDialogue,setShowTargetDateDialogue] = useState(false);
    const [showDeceasedDateDialogue,setShowDeceasedDateDialogue] = useState(false);
    const [showMatterNameDialogue,setShowMatterNameDialogue] = useState(false);
    const [showMatterTypeDialogue,setShowMatterTypeDialogue] = useState(false);
    const [errors,setErrors] = useState([]);
    
    const showErrorDialogue = errors.length > 0;
    const setShowErrorDialogue = () => {
        setErrors([]);
    }

    const [showWorking,setShowWorking] = useState(false);
    if(isLoading){
        return (<LoadingBox/>)
    }

    const {
        textContent:{
            relatedTransaction='',
            targetDateLabel='',
            refLabel='',
            emailTagLabel='',
            startMatter='',
            runTasks='',
            deceasedDateLabel='',
            viewOnExizent='',
            sendToExizent='',
            errorsFound=''
        }
    } = brandContext;

    const {
        details:{
            id,
            client,
            matterType:{
                name:matterTypeName='',
                id:matterTypeId=0
            },
            matterData,
            emailTag=''
        },
        details,
        accessScope
    } = context;

    let fullName = '';
    if(client){
        try{
            if('primaryContact' in client){
                ({primaryContact:{fullName}} = client);
            }   
        }
        catch(e){
            // cWarn(e)
        }
    }

    let dueDate = '';
    let deceasedDate = '';
    let isProspect = false;
    let matterName = '';
    let exizentCaseId = '';
    if(matterData){
        ({dueDate,isProspect,matterName,deceasedDate,exizentCaseId} = matterData);
    } 
    
    if(matterName === '' || matterName === null){
        matterName = getDefaultMatterName(details)
    }
    
    if(isProspect){
        showPropelTasks = false;
    }


    let dueDateFormatted = '';
    if(dueDate){
        const dateObj = new Date(dueDate);
        const options = { year: 'numeric', month: 'short', day: 'numeric'};
        dueDateFormatted = new Intl.DateTimeFormat('en-GB', options).format(dateObj);
    }

    let deceasedDateFormatted = '';
    if(deceasedDate){
        const dateObj = new Date(deceasedDate);
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        deceasedDateFormatted = new Intl.DateTimeFormat('en-GB', options).format(dateObj);
    }

    const convertToFullMatter = () => {
        const apiPath = `${process.env.REACT_APP_API_URL}/matterconvertfromprospect/${id}`;

        axios.post(apiPath,{},{
            timeout: 300000,
            withCredentials:true
        }).then(res => {
            window.location.reload();
        }).catch(err => {
            cWarn(err); 
        });
    }


    const propelTasks = () => {
        setShowWorking(true);
        const apiPath = `${process.env.REACT_APP_API_URL}/propelchecklisttasks/${accessScope}/${id}`;
        axios.post(apiPath,{},{
            timeout: 300000,
            withCredentials:true
        }).then(res => {
            
            widgetsContext.nudge('tasksUpdate');

            setShowWorking(false);
        }).catch(err => {
            setShowWorking(false);
            cWarn(err); 
        });
    }

    const isExecutry = matterTypeId === 5;

    const dispatchToExizent = () => {
        setShowWorking(true);
        const apiPath = `${process.env.REACT_APP_API_URL}/sendtoexizent/${id}`;
        axios.post(apiPath,{},{
            timeout: 300000,
            withCredentials:true
        }).then(res => {
            
            const {
                data:{
                    output:{
                        errors=[]
                    }
                }
            } = res;
            if(errors.length > 0){
                // there are errors, show the error modal
                setErrors(errors);
            }
            else{
                // reload and show the resulting exizent id
                loadData();
            }

            setShowWorking(false);
        }).catch(err => {
            setShowWorking(false);
            cWarn(err); 
        });
    }

    return (
        <>
            <Box className="boxHeader" sx={{display:'flex'}}>
                <Box sx={{flexGrow:1}}>
                    <Typography>{refLabel} <strong>{id}</strong> | {emailTagLabel}<strong>{emailTag}</strong></Typography>
                    <Typography variant="h1" sx={{marginTop:3, cursor:'pointer'}} onClick={()=>{setShowMatterNameDialogue(true)}}>{matterName}</Typography>
                    <Box sx={{display:'flex', marginTop:3}}>
                        {isProspect ? 
                            <Button className="roundButton" onClick={()=>{setShowMatterTypeDialogue(true)}}>{matterTypeName}</Button>
                            :
                            <Button className="roundButton">{matterTypeName}</Button>
                        }
                        
                        <Typography sx={{lineHeight:'40px', marginLeft:3, cursor:'pointer'}} onClick={()=>{setShowTargetDateDialogue(true)}}>
                            {targetDateLabel} <span className="blueBold" sx={{display:'inline'}}>{dueDateFormatted}</span>
                        </Typography>
                        {isExecutry &&
                            <>
                            <Typography sx={{lineHeight:'40px', marginLeft:3, cursor:'pointer'}} onClick={()=>{setShowDeceasedDateDialogue(true)}}>
                                {deceasedDateLabel} <span className="blueBold" sx={{display:'inline'}}>{deceasedDateFormatted}</span>
                            </Typography>
                            <Box sx={{marginLeft:'10px'}}>
                                {exizentCaseId ?
                                    <Button className="roundButton blueButton" target="_blank" href={`https://legal.exizent.com/Case/Dashboard/${exizentCaseId}`}>{viewOnExizent}</Button>
                                    :
                                    <Button className="roundButton blueButton" onClick={()=>{dispatchToExizent()}}>{sendToExizent}</Button>
                                }
                            </Box>
                            </>
                        }
                        {isProspect && <Button className="roundButton" sx={{marginLeft:'50px'}} onClick={convertToFullMatter}>{startMatter}</Button>}
                    </Box>
                    {showPropelTasks && 
                        <Box>
                            <Button className="roundButton blueButton" onClick={()=>{propelTasks()}}>{runTasks}</Button>
                        </Box>
                    }
                </Box>
                <RelatedTransactions context={context} />
                {showWorking &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                    >
                    <CircularProgress color="inherit" />
                </Backdrop>
                }
          </Box>
          <TabsContainer 
                leftWidgets={leftWidgets}
                widgetMap={widgetMap}
                tabs={tabs}
                context={context}
          >
            {showTargetDateDialogue &&
                <TargetDateDialogue context={context} showDialogue={showTargetDateDialogue} setShowDialogue={setShowTargetDateDialogue}/>
            }
            {showDeceasedDateDialogue &&
                <DeceasedDateDialogue context={context} showDialogue={showDeceasedDateDialogue} setShowDialogue={setShowDeceasedDateDialogue}/>
            }
            {showMatterNameDialogue &&
                <MatterNameDialogue context={context} showDialogue={showMatterNameDialogue} setShowDialogue={setShowMatterNameDialogue}/>
            }
            {showMatterTypeDialogue &&
                <MatterTypeDialogue context={context} showDialogue={showMatterTypeDialogue} setShowDialogue={setShowMatterTypeDialogue}/>
            }
            {showErrorDialogue &&
                <ErrorsWarningDialogue showDialogue={showErrorDialogue} setShowDialogue={setShowErrorDialogue} errors={errors} title={errorsFound} />
            }

            <ArchiveMatterDialogue context={context} matterId={id}/>
            <CreateLetterDialogue context={context} matterId={id}/>
            <SendEmailDialogue context={context} matterId={id}/>
            <SaveCommNoteDialogue context={context} matterId={id}/>
            <CreateDocumentDialogue context={context} matterId={id}/>
        </TabsContainer>
            


          
        </>
    );
}

