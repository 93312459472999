
import React, { useContext } from "react";
import InputBase from '@mui/material/InputBase';
import {
    TextField
} from "@mui/material";

import { BrandContext } from "../../contexts/BrandContext";

import 'dayjs/locale/en-gb';
import { cLog } from "../CustomLogger";

export default function InputBaseComponent (props){

    const brandContext = useContext(BrandContext);

    const {
        textContent
    } = brandContext;

    const {
        fieldName,
        formFields,
        formik:{
            values,
            handleChange,
            touched,
            errors
        },
        formik,
        onChange:additionalOnChange
    } = props;

    const defaultField = {
        submitOnEnter: false
    };

    if(!(fieldName in formFields)){
        cLog(`${fieldName} not found in fields.`);
        return null;
    }

    const runningField = {defaultField, ...formFields[fieldName]};

    const {
        useCms=true,
        label
    } = runningField;

    const extendProps = {...props};
    const filterFields = ['formik','formFields','fieldName','onChange'];
    filterFields.forEach(key => delete extendProps[key]);

    let fieldlabel = label;
    if(useCms){
        fieldlabel = textContent[runningField.label];
    }


    return  (
        <InputBase
            
            margin="dense"
            id={fieldName}
            value={values[props.fieldName]}
            onChange={(e) => {
                // console.log(e.currentTarget.value);
                props.formik.setFieldValue(props.fieldName, e.currentTarget.value);
                if(typeof additionalOnChange === 'function'){
                    additionalOnChange(e)
                }
            }}
            error={Boolean(touched[fieldName] && errors[fieldName])}
            helperText={props.formik.errors[props.fieldName]}
            label={fieldlabel}
            onKeyUp = {(e) => {
                if(runningField.submitOnEnter){
                    if (e.key === 'Enter') {
                        runningField.submitOnEnter(formik.values);
                    }
                }
            }}
            
            fullWidth
            {...extendProps}
        />
    );
}