import PropTypes from 'prop-types';
// hooks
import useAuth from '../hooks/useAuth';

// components
import { LoadingBox } from '../components/UniversalComponents';
import Login from '../components/Login';

// ----------------------------------------------------------------------

AuthProtect.propTypes = {
  children: PropTypes.node
};

export default function AuthProtect({ children }) {




  const { isLoading, isAuthenticated } = useAuth();
  
  if (isLoading) {
    return <LoadingBox />;
  }
  else{
    console.log(isAuthenticated);
    if (isAuthenticated) {
      return <>{children}</>;  
    }
    else{
      return <Login/> 
    }
  }
  
  

  
}
