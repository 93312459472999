import React, { useContext, useState } from 'react';
import { LoadingBox } from '../../UniversalComponents';
import { WidgetsContext } from './WidgetsContext';
import { leftWidgets, tabs, widgetMap } from './WidgetConfig';
import TabsContainer from '../../Widgets/TabsContainer';
import TargetDateDialogue from '../../Utilities/TargetDateDialogue';
import AddNewTask from '../../Widgets/Tasks/AddNewTask';

export default function WidgetsInner(props) {


    const context = useContext(WidgetsContext); 

    const{
        isLoading,
    } = context;
    const [showTargetDateDialogue,setShowTargetDateDialogue] = useState(false);

    if(isLoading){
        return (<LoadingBox/>)
    }

    const {
        showAddNewTask=false,
        addTaskInboxId=null
    } = context;
    const setShowAddNewTask = (show) => {
        context.setState({
            showAddNewTask:show
        })
    }

  

    return (
        <>
            <TabsContainer 
                leftWidgets={leftWidgets}
                widgetMap={widgetMap}
                tabs={tabs}
                context={context}
            >
                {showTargetDateDialogue &&
                    <TargetDateDialogue context={context} showDialogue={showTargetDateDialogue} setShowDialogue={setShowTargetDateDialogue}/>
                }
            </TabsContainer>
            <AddNewTask 
                showDialogue={showAddNewTask} 
                setShowDialogue={setShowAddNewTask} 
                inboxId={addTaskInboxId}
                selectMatter={true}
                updateCall={()=>{
                    context.nudge('tasksUpdate')
                }}
            />
        </>
    );
}

