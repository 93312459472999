import React, { useContext } from 'react';
import { BrandContext } from '../../../contexts/BrandContext';
import { Container } from '@mui/material';
import Page from '../../Page';
import Header from '../../ReportViews/Valuations/ValuationsHeader';
import CreateValuationContextProvider from './CreateValuationContext';
import CreateValuationInner from './CreateValuationInner';

export default function CreateValuation(props) {

    const brandContext = useContext(BrandContext);

    const {
        textContent:{
            newValuationPageTitle:pageTitle='',
        }
    } = brandContext;



    return (
        
            <Page title={pageTitle}>
                <Container>
                <CreateValuationContextProvider>
                        <Header title={pageTitle}/>
                        <CreateValuationInner/>
                    </CreateValuationContextProvider>
                </Container>
            </Page>
    );
}

