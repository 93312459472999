import React, { useContext, useEffect, useState } from "react";
import { BrandContext } from "../../contexts/BrandContext";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { Formik } from 'formik';
import axios from '../../utils/axios';
import BasicInputField from "../form/BasicInputField";
import { cWarn } from "../CustomLogger";
import { getValidationFromFields, initialValues } from "../UniversalComponents";

export default function ValuationDateDialogue(props){

    const brandContext = useContext(BrandContext);
    const [valuerOptions,setValuerOptions] = useState([]);
    const [isLoaded,setIsLoaded] = useState(false);

    const {
        textContent:{
            setValuationDateTitle='',
            save='',
            cancel=''
        }
    } =  brandContext;

    const {
        showDialogue,
        setShowDialogue,  
        context:{
            loadData,
            matterId,
            details:{
                events=[],
            },
            userRoles=[]
        },     
    } = props;

    let valuationDate = null;
    if(events.length > 0){
        ({startTime:valuationDate} = events[0]);
    }
    if(!valuationDate){
        const date = new Date();
        valuationDate = new Date(date.setMonth(date.getMonth()+1));
    }

    let valuer = null;
    let valuerName = null;
    let valuerId = null;
    if(userRoles){
        if(userRoles.length > 0){
            for(let i=0; i<userRoles.length; i+=1){
                const {
                    roleType:{
                        id:roleTypeId=0
                    }
                } = userRoles[i];
                if(roleTypeId === 8){
                    valuer = userRoles[i];
                    break;
                }
            }
        }
    }
    if(valuer){
        ({
            bdUser:{
                fullName:valuerName,
                id:valuerId
            }
        } = valuer);
    }

   

    const formFields = {
        valuer:{
            label:'valuerLabel',
            type:'select',
            default:valuerId,
            options:valuerOptions,
            required:true
        },
        valuationDate:{
            label:'valuationAppointmentDateLabel',
            type:'datePicker',
            default:valuationDate,
            required:false
        },
        valuationTime:{
            label:'valuationAppointmentTimeLabel',
            type:'timePicker',
            default:valuationDate,
            required:false
        }
    };


     /* eslint-disable */
     useEffect(() => {
        if(!isLoaded){
            const apiPath = `${process.env.REACT_APP_API_URL}/valueroptions`;

            axios.get(apiPath,{
                timeout: 300000,
                withCredentials:true
            }).then(res => {

                const {
                    data
                } = res;
                setValuerOptions(data);
                setIsLoaded(true);
            }).catch(err => {
                cWarn(err); 
            });
        }
    },[isLoaded]);
    /* eslint-enable */

    return(
        <Dialog open={showDialogue} onClose={()=>{setShowDialogue(false)}} fullWidth className="brandedDialogue">
            <DialogTitle>
                {setValuationDateTitle}
            </DialogTitle>
            <Formik
                initialValues={initialValues(formFields)}
                validationSchema={getValidationFromFields(formFields)}
                onSubmit={(values)=>{
                    const apiPath = `${process.env.REACT_APP_API_URL}/setvaluationdate/${matterId}`;
                    axios.post(apiPath,values,{
                        timeout: 300000,
                        withCredentials:true
                    }).then((res) => {
                        const {
                            data:{
                                success=false
                            }
                        } = res;
                        if(success){
                            setShowDialogue(false)
                            loadData();
                        }
                        else{
                            cWarn('There was a problem saving the new valuation');
                        }
                        
                    }).catch(err => {
                        cWarn(err); 
                    });
                }}
            >
                {props => (
                    <>
                        <DialogContent>
                            <form onSubmit={props.handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12} className="inputHolder">
                                        <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName={`valuer`} variant="outlined" sx={{width:'100%'}}/>  
                                    </Grid>
                                    <Grid item xs={12} md={12} className="inputHolder">
                                        <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName={`valuationDate`} variant="outlined" sx={{width:'100%'}}/>  
                                    </Grid>
                                    <Grid item xs={12} md={12} className="inputHolder">
                                        <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName={`valuationTime`} variant="outlined" sx={{width:'100%'}}/>  
                                    </Grid>
                                </Grid>
                            </form>     
                        </DialogContent>
                        <DialogActions>
                        <Button variant="outlined" onClick={()=>{setShowDialogue(false)}}>{cancel}</Button>
                            <Button variant="outlined" onClick={props.handleSubmit}>{save}</Button>
                        </DialogActions>
                    </>
                )}
            </Formik>
        </Dialog>
    );

}