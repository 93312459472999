import React, { useContext } from 'react';
import { BrandContext } from '../../contexts/BrandContext'
import { Box, Container, Typography } from '@mui/material';
import Page from '../Page';


export default function Lost(props) {

    const brandContext = useContext(BrandContext);

    const {
        textContent:{
            lostTitle:pageTitle=''
        }
    } = brandContext;

    return (
        <>
            <Page title={pageTitle}>
                This is the lost page
                <Container>
                    <Box className="pageHeader">
                        <Typography variant="h1">
                            {pageTitle}
                        </Typography>
                    </Box>
                </Container>
            </Page>
        </>
    );
}

