import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Formik } from "formik";
import { BrandContext } from '../../../contexts/BrandContext';
import { LoadingBox, getValidationFromFields, initialValues } from '../../UniversalComponents';
import axios from '../../../utils/axios';
import { cWarn } from '../../CustomLogger';
import BasicInputField from '../../form/BasicInputField';
import { CreateValuationContext } from './CreateValuationContext';


export default function NewValuationScheduleAppt(props){


    const brandContext = useContext(BrandContext);
    const createValuationContext = useContext(CreateValuationContext);
    const {
        showDialogue,
        setShowDialogue,
        formik,
        formFields
    } = props;
    const {
        textContent:{
            cancel='',
            save='',
            scheduleValuationAppointmentHelperText:helperText='',
            scheduleValuationAppointmentSubTitle:dialogSubTitle='',
            appointmentCreating:thanksMessage='',
            scheduleValuationAppointmentTitle:dialogTitle='',
        }
    } = brandContext;

    const {
        submitNewUnit:createMethod
    } = createValuationContext;

    const [showThanks,setShowThanks] = useState(false);    

    return (
        <Dialog open={showDialogue} onClose={()=>{setShowDialogue(false)}}>
            <DialogTitle sx={{textAlign:'center', marginTop:'15px'}}>{dialogTitle}</DialogTitle>
            <Box sx={{padding:'20px'}}>

                {showThanks ? 
                    <Box>
                        <Typography variant="bigText">{thanksMessage}</Typography>
                    </Box>
                :
                <>
                    <DialogContent>
                        <Box sx={{paddingBottom:'20px'}}>
                            <Typography>{helperText}</Typography>
                        </Box>
                        <Box sx={{minWidth:'500px'}}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                    <BasicInputField  formik={formik} formFields={formFields} fieldName="appointmentDate" variant='outlined'/>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <BasicInputField  formik={formik} formFields={formFields} fieldName="appointmentTime" variant='outlined'/>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <BasicInputField  formik={formik} formFields={formFields} fieldName="valuationType" variant='outlined'/>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <BasicInputField sx={{width:'100%'}} formik={formik} formFields={formFields} fieldName="valuer" variant='outlined'/>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <BasicInputField sx={{width:'100%'}} formik={formik} formFields={formFields} fieldName="otherInfo" variant='outlined'/>
                                </Grid>
                            </Grid>
                        </Box>
                        
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>{setShowDialogue(false)}}>{cancel}</Button>
                        <Button onClick={()=>{createMethod(formik.values,true)}}>{save}</Button>
                    </DialogActions>
                </>
                }

            </Box>
        </Dialog>
    );
}

