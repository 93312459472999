import { Box, Button, Divider, IconButton, Paper, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BrandContext } from "../../contexts/BrandContext";
import { getDefaultMatterName } from "../ReportViews/Matters/MatterUtilities";
import { es } from "date-fns/locale";
import { formatAddressToStringShort } from "../UniversalComponents";
import AddRelatedTransactionDialogue from "./AddRelatedTransactionDialogue.js";
import DeleteIcon from '@mui/icons-material/Delete';
import axios from '../../utils/axios';
import { cWarn } from "../CustomLogger.js";

export default function RelatedTransactions(props){

    const {
        context
    } = props;

    const brandContext = useContext(BrandContext);

    const {
        textContent:{
            relatedTransaction='',
            addRelatedTransaction=''
        }
    } = brandContext;

    const {
        relatedMatters
    } = context;
    
    const navigate = useNavigate();
    const [showAddRelation,setShowAddRelation] = useState(false);
    

    return (
        <Paper className="relatedMattersContainer">
            <Typography variant="h3">{relatedTransaction}</Typography>
            <Box
                sx={{display: 'flex', flexDirection: 'column', height:'100%'}}
            >
                <Box className="resultsContainer">
                    {relatedMatters.map((matter,index)=>(
                        <RelatedTransResult key={index} matter={matter} context={context} />
                    ))}
                </Box>
                <Button className="roundButton smallButton blueButton" onClick={()=>{setShowAddRelation(true)}} sx={{ mt: "auto" }}>
                    {addRelatedTransaction}
                </Button>
            </Box>
            {showAddRelation &&
                <AddRelatedTransactionDialogue showDialogue={showAddRelation} setShowDialogue={setShowAddRelation} context={context} />
            }
        </Paper>
    );

}

const removeRelation = (id,context) => {

    const {
        details:{
            id:matterId
        },
        loadData,
    } = context;

    const apiPath = `${process.env.REACT_APP_API_URL}/removerelatedmatter/${matterId}`;
    axios.post(apiPath,{
        matter:id,
    },{
        timeout: 300000,
        withCredentials:true
    }).then((res) => {
        const {
            data:{
                success=false
            }
        } = res;
        if(success){
            loadData();
        }
        else{
            cWarn('There was a problem removing the relation');
        }
        
    }).catch(err => {
        cWarn(err); 
    });
}


const RelatedTransResult = (props) => {
    const {
        matter:{
            matterData=null,
            estateAgencyData=null,
            matterType:{
                id:matterTypeId,
                matterDepartment:{
                    id:departmentId
                }
            }
        },
    } = props;
    if(departmentId === 1){
        if(estateAgencyData){
            return <EAResult {...props} />
        }
        return <ConveyancingResult {...props} />
    }
    if(departmentId === 3){
        return <PCResult {...props} />
    }

}

const EAResult = (props) => {
    
    const {textContent} = useContext(BrandContext);

    const {
        matter:{
            id,
            case:mainCase=null,
            matterType:{
                name:matterTypeName
            },
            estateAgencyData:{
                valuationType=null,
            }
        }
    } = props;
    
    let valuationTypeString='';
    let valuationTypeId = null;
    if(valuationType){
        ({textKey:valuationTypeString,id:valuationTypeId} = valuationType);
    }

    return (
        <BaseResult id={id} linkPath={`/valuation/${id}`} {...props}>
            <Box>
                <Typography variant="body1Bold">Valuation of {formatAddressToStringShort(mainCase)}</Typography>
            </Box>
            <Box>
                <Typography component="span" variant="smallText">
                    {textContent[valuationTypeString]}
                </Typography>
            </Box>
        </BaseResult>
    );
}

const BaseResult = (props) => {
    const {
        id:relationId,
        linkPath='',
        context,
    } = props;
    return (
        <Box className="resultContainer" >
            <Box className="resultsInner" >
                <Box className="resultsContents" onClick={() => {
            window.open(linkPath, '_blank');
        }}>
                    {props.children}
                </Box>
                <Box className="resultsTools">
                    <IconButton onClick={()=>{removeRelation(relationId,context)}}>
                        <DeleteIcon/>
                    </IconButton>
                </Box>
            </Box>
            <Divider />
        </Box>
    );
}

const ConveyancingResult = (props) => {
    const {
        matter:{
            id,
            case:mainCase=null,
            matterType:{
                name:matterTypeName
            }
        }
    } = props;

    return (
        <BaseResult id={id} linkPath={`https://beta.doobray.com/transactions&fn=edit&trans=${id}`} {...props}>
            {matterTypeName} of {formatAddressToStringShort(mainCase)}
        </BaseResult>
    );
}

const PCResult = (props) => {
    const {
        matter:{
            id,
            matterData,
            matterUserRoles=[]
        },
        matter
    } = props;
                  
    
    let dueDate = '';
    let matterName = '';
    if(matterData){
        ({dueDate,matterName} = matterData);
    } 
    if(matterName === '' || matterName === null){
        matterName = getDefaultMatterName(matter);
    }
    let dueDateFormatted = '';
    if(dueDate){
        const dateObj = new Date(dueDate);
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        dueDateFormatted = new Intl.DateTimeFormat('en-GB', options).format(dateObj);
    }

    const getFirstCharacter = (inputString) => inputString.length > 0 ? inputString[0].toUpperCase() : '';

    return (
        
        <BaseResult id={id} linkPath={`/matter/${id}`} {...props}>    
            <Box><Typography variant="body1Bold">{matterName}</Typography></Box>
            <Box>
                {matterUserRoles.map((userRole,index)=>{
                    const{
                        bdUser:{
                            firstName,
                            lastName,
                            userFirmsLinks=[]
                        }
                    } = userRole;
                    let initials = '';
                    if(userFirmsLinks.length > 0){
                        const {
                            customInitials=''
                        } = userFirmsLinks[0];
                        if(customInitials !== ''){
                            initials = customInitials;
                        }
                    }
                    if(!initials){
                        initials = `${getFirstCharacter(firstName)}${getFirstCharacter(lastName)}`;
                    }
                    const isLast = index === matterUserRoles.length-1;
                    return (
                        <Typography key={index} component="span" variant="smallText">
                            {initials}{!isLast && '/'}
                        </Typography>
                    )

                })}
            </Box>
            <Box>
                <Typography variant="smallText">{dueDateFormatted}</Typography>
            </Box>
            <Divider/>
        </BaseResult>
    );
}