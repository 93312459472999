import React from "react";
import {Box, Card, CircularProgress, Fab, Typography} from "@mui/material";
import Popover from "@mui/material/Popover/Popover";
import * as Yup from 'yup';
import { cWarn } from "./CustomLogger";

export const CardContainer = (props) => (
    <Box mb={3}>
        <Card sx={{ p: 3 }}  mb={3} className={props.className}>
            {props.children}
        </Card>
    </Box>
);

export const InfoPop = (props) => {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    if(props.children) {

        return (<>
            <Fab size="small" aria-describedby={id} variant="contained" onClick={handleClick}>
                <Typography sx={{fontSize: 26}}>?</Typography>
            </Fab>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}

            >
                <Box sx={{width: 400, padding: 2}}>
                    {props.children}
                </Box>
            </Popover>
        </>);
    }
    return '';

};


export const LoadingBox = () =>(
    <Box sx={{ display: 'flex' }} justifyContent="center" m={2}>
        <CircularProgress color="primary" />
    </Box>
);
export const BigTitle = (props) => (
    <div className="slideTitle">
        <Typography align="center" variant="h2" sx={{textTransform: 'uppercase'}}>
            {props.children}
        </Typography>
    </div>
)

export const formatAddressToString = (address) => {

    if(address){
        const line1Parts = [];

        const {houseNo='',houseName='',streetName='',town='',postcode=''} = address;

        if(houseNo){
            line1Parts.push(houseNo);
        }
        if(houseName){
            line1Parts.push(houseName);
        }
        if(streetName){
            line1Parts.push(streetName);
        }
        const line1 = line1Parts.join(' ');

        const addr = [];
        if(line1){
            addr.push(line1);
        }
        
        if(town){
            if(town !== 'null'){
                addr.push(town);
            }
        }
        
        if(postcode){
            addr.push(postcode);
        }
        
        return addr.join(', ');
    }
    return '';
};

export const formatAddressToStringShort = (address) =>  {
    if(address){
        const line1Parts = [];

        const {houseNo='',houseName='',streetName='',town=''} = address;

        if(houseNo){
            line1Parts.push(houseNo);
        }
        if(houseName){
            line1Parts.push(houseName);
        }
        if(streetName){
            line1Parts.push(streetName);
        }
        if(town){
            if(town !== 'null'){
                line1Parts.push(town);
            }
        }
        
        
        return line1Parts.join(' ');
    }
    return '';
};

export const getComponentsFromUtcDate = (dateString) => {
    
    try{
        const dateParts = dateString.split('T');
        const dateBits = dateParts[0].split('-');
        const timeBits = dateParts[1].split(':');
        return {
            year:dateBits[0],
            month:dateBits[1],
            day:dateBits[2],
            hours:timeBits[0],
            mins:timeBits[1],
            secs:timeBits[2]
        }
    }
    catch(e){
        return {
            year:'',
            month:'',
            day:'',
            hours:'',
            mins:'',
            secs:''
        }
    }
    
    
}


export const initialValues = (fields) => {


    const initialValues = {};
    const keys = Object.keys(fields);
    const values = Object.values(fields);
    for (let i = 0; i < keys.length; i += 1) {
        if('value' in values[i]){
            initialValues[keys[i]] = values[i].value;
        }
        else{
            initialValues[keys[i]] = ('default' in values[i] ? values[i].default : '');
        }
    }
    return initialValues;
}

export const getValidationFromFields = (fields) => {
    
    const keys = Object.keys(fields);
    const values = Object.values(fields);
    const validation = {};
    for (let i = 0; i < keys.length; i += 1) {
        const field = values[i];
        const key = keys[i];
        let required = false;
        if(!('required' in field)){
            required = true;
        }
        else{
            required = field.required;
        }
        if(required){
            validation[key] = Yup.string().required('Required')
        }
    }
    const validationSchema = Yup.object().shape(validation);
    return validationSchema;
}

export const getLocalStorageItem = (key) => {
    try {
        // Get the item from local storage
        const item = localStorage.getItem(key);
        // If the item exists, parse it as JSON
        if (item) {
            return JSON.parse(item);
        }
        // If the item doesn't exist, return null or a default value
        return {};
    } catch (error) {
        // Handle any errors that occur during parsing
        cWarn(`Error parsing JSON from local storage key "${key}":`, error);
        return {};
    }
};

export const setLocalStorageItem = (key, value) => {
    try {
        // Convert the value to a JSON string
        const jsonString = JSON.stringify(value);
        // Store the JSON string in local storage with the given key
        localStorage.setItem(key, jsonString);
    } catch (error) {
        // Handle any errors that occur during stringification
        console.warn(`Error stringifying and storing JSON to local storage key "${key}":`, error);
    }
};




export const getInitialSearchFieldValues = (storageHook,formFields) => {

    const searchData = getLocalStorageItem(storageHook);
    const fields = formFields;
    const initialValues = {};
    const keys = Object.keys(fields);
    const values = Object.values(fields);
    for (let i = 0; i < keys.length; i += 1) {
        if(keys[i] in searchData){
            if(fields[keys[i]].type === 'checkbox'){
                initialValues[keys[i]] = (searchData[keys[i]] === 'true');
            }
            else if(fields[keys[i]].type === 'datePicker'){
                if(searchData[keys[i]]){
                    const dateParts = searchData[keys[i]].split('-');
                    const val = new Date();
                    val.setDate(dateParts[0]);
                    val.setMonth(dateParts[1]-1);
                    val.setUTCFullYear(dateParts[2]);
                    initialValues[keys[i]] = val;
                }
            }
            else{
                initialValues[keys[i]] = searchData[keys[i]];
            }

        }
        else{
            initialValues[keys[i]] = ('default' in values[i] ? values[i].default : '');
        }
    }
    // context.softSetValues(initialValues);
    return initialValues;
}