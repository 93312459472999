import React, { useContext, useEffect, useState } from "react";
import { MattersContext } from "./MattersContext";
import { BrandContext } from "../../../contexts/BrandContext";
import { Box, Button, Container, Link, Typography } from "@mui/material";
import CustomMenuList from "../../CustomMenuList";
import { Archive, Delete, FolderOpen, MoreHoriz, PictureAsPdf } from "@mui/icons-material";
import { MatterDetailsContext } from "../../pages/matters/MatterDetailsContext";
import axios from '../../../utils/axios';


export default function MattersHeader(props){

    const context = useContext(MattersContext);
    const brandContext = useContext(BrandContext);
    const detailsContext = useContext(MatterDetailsContext);

    const {
        textContent:{
            dbNavNewMatter='',
            buttonDelete='',
            buttonArchive='',
            buttonExportToPdf='',
            buttonOpenInNewTab=''
        },
        textContent={}
    } = brandContext;


    const defaultTools = [
        {
            text: buttonOpenInNewTab,
            iconComponent: <FolderOpen/>,
            action: () => {
                // console.log('open details dialogue');
            },
            branded: true
        },
        {
            text: buttonExportToPdf,
            iconComponent: <PictureAsPdf/>,
            action: () => {
                const queryParams = new URLSearchParams(window.location.search);
                const apiPath = `${process.env.REACT_APP_API_URL}/matters/?topdf=1&${queryParams}`;
        
                axios.get(apiPath,{
                    timeout: 300000,
                    withCredentials:true,
                    responseType: "arraybuffer"
                }).then(response => {
                    const blob = new Blob([response.data]);
                    const link = document.createElement('a')
                    const contentDisposition = response.headers['content-disposition'];    
                    const filenameMatch = contentDisposition.match(/filename="(.+)"/);
                    const filename = filenameMatch ? filenameMatch[1] : 'report.pdf'; 
                    link.href = window.URL.createObjectURL(blob);
                    link.download = filename;
                    link.click()
        
                }).catch(err => {
                    console.log('caught the error');
                    console.log(err);
                });
            },
            branded: true
        },
        {
            text: buttonDelete,
            iconComponent: <Delete/>,
            action: () => {
                // console.log('open details dialogue');
            },
            branded: true
        }
    ];

    let detailsTools = [];
    if(detailsContext){
        ({tools:detailsTools=[]} = detailsContext);
    }
    

    const tools = [...defaultTools,...detailsTools];

    for(let i=0;i<tools.length;i+=1){
        if('textKey' in tools[i]){
            tools[i]['text'] = textContent[tools[i]['textKey']];
        }
    }

    const {
        pageTitle=''
    }= props;


    const [hasScrolled, setHasScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
        // Check if the page has been scrolled
        const scrolled = window.scrollY > 0;

        // Update the state based on the scroll position
        setHasScrolled(scrolled);
        };

        // Attach scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Remove event listener on component unmount
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    let borderStyle = {};
    if(hasScrolled){
        borderStyle = {
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)'
        }
    }

    return (
        
            <Box className="fixedHeaderContainer">
                <Box className="fixerBox" sx={borderStyle}>
                    <Container maxWidth="xl"  >
                        <Box className="pageHeader" sx={{marginBottom:10, display: 'flex'}}>
                            <Typography variant="h1" sx={{width:'100%'}}>
                                {pageTitle}
                            </Typography>
                            <Button style={{flexShrink:0, marginBottom:0, marginRight:20}} className="roundButton blueButton" href="/matters/newmatter" component={Link} to="/matters/newmatter">
                                {dbNavNewMatter}
                            </Button>
                            <CustomMenuList sx={{flexShrink:0}}
                                size="medium"
                                id="title_bar"
                                context={context}
                                customButton = {<Button style={{minWidth:0}} variant="outlined"><MoreHoriz/></Button>}
                                options={tools}/>
                        </Box>
                    </Container>
                </Box>
            </Box>
            
        
    );

}