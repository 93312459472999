import React, { useContext } from "react";
import { BrandContext } from "../../contexts/BrandContext";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";


export default function ErrorsWarningDialogue(props){

    const brandContext = useContext(BrandContext);

    const {
        textContent:{
            save='',
            cancel=''
        }
    } =  brandContext;

    const {
        showDialogue,
        setShowDialogue,  
        title='',
        msg='',
        errors=[] 
    } = props;

  
    return(
        <Dialog open={showDialogue} onClose={()=>{setShowDialogue(false)}} fullWidth className="brandedDialogue">
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                {msg && <Typography>{msg}</Typography>}
                {errors.map((error,index)=>(
                    <Box key={index}>
                        {error}
                    </Box>
                ))}
            </DialogContent>
            <DialogActions>
            <Button variant="outlined" onClick={()=>{setShowDialogue(false)}}>{cancel}</Button>
                <Button variant="outlined" onClick={props.handleSubmit}>{save}</Button>
            </DialogActions>
        </Dialog>
    );

}