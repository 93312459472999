import React from "react";
import Box from "@mui/material/Box";
import * as Yup from "yup";



export const getAddressFields = (context,submitFunction) => {

    if(!context.isLoading){
 

        
    }

    const today = new Date();
    const date = new Date();
    const endDate = new Date(date.setMonth(date.getMonth()+1));

    return {
        'textSearch': {
            'label': 'filtersKeywordsPrompt',
            'submitOnEnter': submitFunction,
            required:false
        },
        'selectValuer': {
            'label': 'filtersValuer',
            'type':'select',
            'options':context.valuerOptions ? context.valuerOptions : [],
        },
        'selectUserCreated': {
            'label': 'filtersUserCreated',
            'type':'select',
            'options':context.userCreatedOptions ? context.userCreatedOptions : [],
        },
        'useDateRange':{
            'label': 'filterByDateLabel',
            'type':'checkbox',
            default:false,
            required:false
        },
        'fromDate': {
            'label': 'searchFromDate',
            'type':'datePicker',
            'default':today,
            'chip':false,
            required:false
        },
        'toDate': {
            'label': 'searchToDate',
            'type':'datePicker',
            'default':endDate,
            'chip':false,
            required:false
        },
        'valuationType': {
            'label': 'filtersValuationType',
            'type':'select',
            'options':context.valuationTypeOptions ? context.valuationTypeOptions : [],
            cmsOptions:true
        },
        'transactionType': {
            'label': 'filtersTransactionType',
            'type':'select',
            'options':context.transactionTypeOptions ? context.transactionTypeOptions : [],
            cmsOptions:true
        },

    };
};

export const getInitialFieldValues = (context,parameters) => {
    const fields = getAddressFields(context);
    const initialValues = {};
    const keys = Object.keys(fields);
    const values = Object.values(fields);
    for (let i = 0; i < keys.length; i += 1) {
        if(parameters.get(keys[i])){
            if(fields[keys[i]].type === 'checkbox'){
                initialValues[keys[i]] = (parameters.get(keys[i]) === 'true');
            }
            else if(fields[keys[i]].type === 'datePicker'){
                const dateParts = parameters.get(keys[i]).split('-');
                const val = new Date();
                val.setDate(dateParts[0]);
                val.setMonth(dateParts[1]-1);
                val.setUTCFullYear(dateParts[2]);
                initialValues[keys[i]] = val;
            }
            else{
                initialValues[keys[i]] = parameters.get(keys[i]);
            }

        }
        else{
            initialValues[keys[i]] = ('default' in values[i] ? values[i].default : '');
        }
    }
    context.softSetValues(initialValues);
    return initialValues;
}

export const formatTaxiBookingData = (taxiBooking) => {

    const studentsToBookings = {
        active:[],
        cancelled:[]
    };
    const fromPoints = {
        active:[],
        cancelled:[]
    };
    const toPoints = {
        active:[],
        cancelled:[]
    };
    const schools = {
        active:[],
        cancelled :[]
    };
    const bookers = {
        active:[],
        cancelled :[]
    };

    const flights = {
        active:[],
        cancelled:[]
    };

    const waypointToCache = {};
    const waypointFromCache = {};
    const schoolsCache = {};
    const bookerCache = {};
    const flightsCache = {};

    let hasInvoice = false;
    for(let i=0; i<taxiBooking.legToTaxis.length; i+=1){

        

        for(let k=0; k<taxiBooking.legToTaxis[i].bookingLeg.booking.studentToBookings.length; k+=1){
            if(taxiBooking.legToTaxis[i].bookingLeg.booking.isCancelled){
                studentsToBookings.cancelled.push(taxiBooking.legToTaxis[i].bookingLeg.booking.studentToBookings[k]);
            }
            else{
                if(!(taxiBooking.legToTaxis[i].bookingLeg.booking.studentToBookings[k].student.school.id in schoolsCache)){
                    schools.active.push(taxiBooking.legToTaxis[i].bookingLeg.booking.studentToBookings[k].student.school);
                    schoolsCache[taxiBooking.legToTaxis[i].bookingLeg.booking.studentToBookings[k].student.school.id] = 1;
                }
                studentsToBookings.active.push(taxiBooking.legToTaxis[i].bookingLeg.booking.studentToBookings[k]);

            }
            /* eslint-disable */

            /* eslint-enable */
        }

        for(let k=0; k<taxiBooking.legToTaxis[i].bookingLeg.booking.studentToBookings.length; k+=1){
            if(taxiBooking.legToTaxis[i].bookingLeg.booking.isCancelled && !(taxiBooking.legToTaxis[i].bookingLeg.booking.studentToBookings[k].student.school.id in schoolsCache)){
                schools.cancelled.push(taxiBooking.legToTaxis[i].bookingLeg.booking.studentToBookings[k].student.school);
            }
        }



        for(let j=0; j<taxiBooking.legToTaxis[i].bookingLeg.legWaypoints.length; j+=1) {
            const waypoint = taxiBooking.legToTaxis[i].bookingLeg.legWaypoints[j];
            if(waypoint.pointTime){
                if(!waypoint.cancelled && !(waypoint.pointLocation.id in waypointFromCache)){
                    fromPoints.active.push(waypoint);
                    waypointFromCache[waypoint.pointLocation.id] = 1;
                }
            }
            else if(!waypoint.cancelled && !(waypoint.pointLocation.id in waypointToCache)){
                toPoints.active.push(waypoint);
                waypointToCache[waypoint.pointLocation.id] = 1;

            }
        }
        for(let j=0; j<taxiBooking.legToTaxis[i].bookingLeg.legWaypoints.length; j+=1) {
            const waypoint = taxiBooking.legToTaxis[i].bookingLeg.legWaypoints[j];
            if(waypoint.pointTime){
                if(waypoint.cancelled && !(waypoint.pointLocation.id in waypointFromCache)){
                    fromPoints.cancelled.push(waypoint);
                }
            }
            else if(waypoint.cancelled && !(waypoint.pointLocation.id in waypointToCache)){
                toPoints.cancelled.push(waypoint);
            }
        }


        if(!taxiBooking.legToTaxis[i].bookingLeg.booking.isCancelled && !(taxiBooking.legToTaxis[i].bookingLeg.booking.parentContact.id in bookerCache) ) {
            bookerCache[taxiBooking.legToTaxis[i].bookingLeg.booking.parentContact.id]=1;
            bookers.active.push(taxiBooking.legToTaxis[i].bookingLeg.booking.parentContact);
        }


        if(!taxiBooking.legToTaxis[i].bookingLeg.booking.isCancelled){
            const{origin,destination} = taxiBooking.legToTaxis[i].bookingLeg.booking.bookingJson;

            let fData = false;
            if('flightData' in origin.destination){
                fData = {
                    flightNo:origin.destination.flightNo,
                    data:origin.destination.flightData
                };

            }
            if('flightData' in destination.destination){
                fData = {
                    flightNo: destination.destination.flightNo,
                    data: destination.destination.flightData
                }
            }
            if(fData){
                fData.data.flightNo = fData.flightNo;
                if(!(fData.flightNo in flightsCache)){
                    flights.active.push(fData.data);
                    flightsCache[fData.flightNo] = destination.destination.flightData;
                }
            }
        }
        if(taxiBooking.legToTaxis[i].travelCoCustomerLineItems.length > 0){
            hasInvoice = true;
        }

    }


    for(let i=0; i<taxiBooking.legToTaxis.length; i+=1){
        if(taxiBooking.legToTaxis[i].bookingLeg.booking.isCancelled && !(taxiBooking.legToTaxis[i].bookingLeg.booking.parentContact.id in bookerCache)){
            bookers.cancelled.push(taxiBooking.legToTaxis[i].bookingLeg.booking.parentContact);
        }
        if(taxiBooking.legToTaxis[i].bookingLeg.booking.isCancelled){
            const{origin,destination} = taxiBooking.legToTaxis[i].bookingLeg.booking.bookingJson;

            let fData = false;
            if('flightData' in origin.destination){
                fData = {
                    flightNo:origin.destination.flightNo,
                    data:origin.destination.flightData
                };

            }
            if('flightData' in destination.destination){
                fData = {
                    flightNo: destination.destination.flightNo,
                    data: destination.destination.flightData
                }
            }
            if(fData){
                fData.data.flightNo = fData.flightNo;
                if(!(fData.flightNo in flightsCache)){
                    flights.cancelled.push(fData.data);
                }
            }
        }
    }

    const pointTime = (fromPoints.active.length > 0 ? fromPoints.active[0].pointTime : fromPoints.cancelled[0].pointTime);
    const fullyCancelled = studentsToBookings.active.length === 0;



     return {
         studentsToBookings,
         fromPoints,
         toPoints,
         pointTime,
         fullyCancelled,
         bookers,
         schools,
         flights,
         hasInvoice
     };
}



export const BookingLoopExpanded = (props) => (
    <>
        {props.looper.active.map((studentToBooking, j) => (
            <Box studentToBooking={studentToBooking} key={j}>
                {props.child(studentToBooking)}
            </Box>
        ))}
        {props.looper.cancelled.map((studentToBooking, j) => (
            <Box additionalClass="bookingCancelled" studentToBooking={studentToBooking} key={j}>
                {props.child(studentToBooking)}
            </Box>
        ))}
    </>
);

export const getFieldValidationSchema = () => {
    const validationSchema = Yup.object().shape({

    });
    return validationSchema;
}