import axios from '../../utils/axios';
import { customLogger } from '../CustomLogger';


export const selectAddressAndMoveOn = (addr,formik,callBack) =>{

    const apiPath = `${process.env.REACT_APP_API_URL}/address/lookup/${addr.id}`;

    axios.get(apiPath,{
        timeout: 30000,
        withCredentials:true
    }).then(res => {
        
        const {data} = res;
        const {
            line_1:line1 = '',
            line_2:line2='',
            thoroughfare='',
            building_name:buildingName = '',
            building_number:houseNumber = '',
            post_town:townOrCity = '',
            postcode = '',
            dependant_locality:locality = '',
            country=''
        } = data;

        let street = '';
        if(thoroughfare){
            street = thoroughfare;
        }
        else{
            street = line2;
        }


        const houseName = (!buildingName && !houseNumber ? line1 : buildingName);
        
        const townParts = [];
        if(locality){
            townParts.push(locality);
        }
        if(townOrCity){
            townParts.push(townOrCity);
        }
        const town = townParts.join(', ');

        if(callBack){
            callBack({
                houseName,
                houseNumber,
                street,
                town,
                country,
                postcode
            },
            formik);
        }
    }).catch(err => {
        customLogger(err);
    });



}