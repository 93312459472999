import React, { memo, useContext, useEffect, useState } from "react";
import { styled } from "@mui/styles";
import { alpha, Box, InputBase, Paper, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { BrandContext } from "../contexts/BrandContext";
import { getValidationFromFields, initialValues, LoadingBox } from "./UniversalComponents";
import axios from '../utils/axios';
import { cWarn } from "./CustomLogger";
import BasicInputField from "./form/BasicInputField";
import { Formik } from "formik";
import throttle from 'lodash/throttle';

export default function SearchHeaderTool(props){

    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(1),
          width: 'auto',
        },
      }));

      const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }));
      
      


      const showSearchModal = (term) => {
        setSetSearchGo({
            showDialogue:true,
            term
        });
      }
    const [searchValue,setSearchValue] = useState('');

    const [searchGo,setSetSearchGo] = useState({
        showDialogue:false,
        term:''
    });
    const {
        showDialogue,
        term
    } = searchGo;

    const handleChange = (event) => {
        setSearchValue(event.target.value);
      };
    


    const formFields = {
        searchField:{
            default:'',
            required:false,
            type:'inputBase'
        }
    };
      


    return (
        
        <Formik
            initialValues={initialValues(formFields)}
            validationSchema={getValidationFromFields(formFields)}
            onSubmit={(values)=>{
                
            }}
        >
            {props => (
                <Box sx={{position:'relative'}}>
                    <Search>
                        <SearchIconWrapper>
                        <SearchIcon />
                        </SearchIconWrapper>
                        <BasicInputField className="headerSearchInput" formik={props} formFields={formFields} fieldName={`searchField`} autoComplete="off"/>
                    </Search>
                    <SearchResults term={props.values.searchField}/>
                </Box>
            )}
        </Formik>
    );

}

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));

const MyInput = memo(({ value, onChange }) => (
    <StyledInputBase
        value={value}
        placeholder="Search…"
        inputProps={{ 'aria-label': 'search' }}
        
        onChange={onChange}
    />
  ));

const SearchResults = (props) => {
    const brandContext = useContext(BrandContext);

    const {
        showDialogue,
        setShowDialogue,
        term=''
    } = props;
    const {
        textContent:{
            cancel='',
            searchTitle:title='',
        },
        brand:{
            mode=''
        }
    } = brandContext;

    const [isLoaded,setIsLoaded] = useState(false);
    const [results,setResults] = useState([]);

    /* eslint-disable */
    useEffect(() => {
        if(term !== ''){
            loadData({term});
        }
    },[term]);
    /* eslint-enable */

    const loadData = React.useMemo( () => 

        throttle((request, callback) => {

            const apiPath = `${process.env.REACT_APP_API_URL}/basicsearch`;

            axios.post(apiPath,{
                term:request.term
            },{
                timeout: 300000,
                withCredentials:true
            }).then(res => {

                const {
                    data
                } = res;
                setResults(data);
                setIsLoaded(true);
            }).catch(err => {
                cWarn(err); 
            });
        },200),
        [props],
    );


    const navigateToRes = (id) => {
        // navigate(`/matter/${id}`);
        let modePath = '';
        if(mode === 'pcCo'){
            modePath = '/matter';
        }
        else if(mode === 'eaCo'){
            modePath = '/valuation';
        }
        window.location.href=`${modePath}/${id}`;
    }

    const max = 20;
   
    if(term) {
        return (
            <Box sx={{position:'absolute', top:36, left:10, width:300}}>
                <Paper sx={{padding:'20px'}}>
                    {isLoaded ? 
                    <Box sx={{width:'300px'}}>
                        {results.slice(0, max).map((result,index)=>{
                            
                            const {
                                value:id=0,
                                label
                            } = result;

                            return (
                            <Box key-={index}>
                                <Typography sx={{cursor:'pointer', marginBottom:'5px'}} onClick={()=>{
                                    navigateToRes(id);
                                }}>
                                    {label}
                                </Typography>
                            </Box>
                            );
                        })}
                    </Box>
                    :
                    <LoadingBox/>
                    }
                </Paper>
            </Box>
        );
    }
    return null;
}