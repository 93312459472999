import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React, { useContext, useState } from 'react';
import { BrandContext } from '../../../contexts/BrandContext';
import { DialogTitle } from '@mui/material';
import axios from '../../../utils/axios';
import { cWarn } from '../../CustomLogger';
import { LoadingBox } from '../../UniversalComponents';
import { ValuationsContext } from './ValuationsContext';

export default function DeleteDialogue(props){
    
    const brandContext = useContext(BrandContext);

    const [showWorking,setShowWorking] = useState(false);

    const {
        showDialogue,
        setShowDialogue,
        updateCall=false,
        fields:formFields,
    } = props;
    const {
        textContent:{
            cancel='',
            taskButtonDelete='',
            areYouSureYouWishToDeleteTheseValuations:areYouSure='',
            deleteItemsTitle:title=''
        }
    } = brandContext;
    

    const valuationsContext = useContext(ValuationsContext);

    const {
        resSelected=[]
    } = valuationsContext;

    const runDelete = () => {
        setShowWorking(true);
        const apiPath = `${process.env.REACT_APP_API_URL}/valuations/delete/`;
        axios.post(apiPath,{
            toDelete:resSelected
        },{
            timeout: 300000,
            withCredentials:true
        }).then((res) => {
            setShowDialogue(false);
            setShowWorking(false);
            if(updateCall){
                updateCall();
            }
        }).catch(err => {
            cWarn(err);
        });
    }

    return (
        <Dialog open={showDialogue} onClose={()=>{setShowDialogue(false)}}>
            <DialogTitle>{title}</DialogTitle>
            {showWorking ? 
                <LoadingBox/>
                :
                <>
                    <DialogContent>
                        {areYouSure}                
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>{setShowDialogue(false)}}>{cancel}</Button>
                        <Button onClick={runDelete}>{taskButtonDelete}</Button>
                    </DialogActions>
                </>
            }
            
        </Dialog>
    );

}