import React, { useContext } from "react";
import { BrandContext } from "../../contexts/BrandContext";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { Formik } from 'formik';
import axios from '../../utils/axios';
import BasicInputField from "../form/BasicInputField";
import { cWarn } from "../CustomLogger";
import { getValidationFromFields, initialValues } from "../UniversalComponents";

export default function TargetDateDialogue(props){

    const brandContext = useContext(BrandContext);

    const {
        textContent:{
            setTargetDateTitle='',
            save='',
            cancel=''
        }
    } =  brandContext;

    const {
        showDialogue,
        setShowDialogue,  
        context:{
            loadData,
            matterId,
            details:{
                matterData
            }
        },     
    } = props;

    let dueDate = null;
    if(matterData){
        ({dueDate} = matterData);
    } 
    if(!dueDate){
        const date = new Date();
        dueDate = new Date(date.setMonth(date.getMonth()+1));
    }

   

    const formFields = {
        targetDate:{
            label:'targetDateLabel',
            type:'datePicker',
            default:dueDate,
            required:false
        }
    };

    return(
        <Dialog open={showDialogue} onClose={()=>{setShowDialogue(false)}} fullWidth className="brandedDialogue">
            <DialogTitle>
                {setTargetDateTitle}
            </DialogTitle>
            <Formik
                initialValues={initialValues(formFields)}
                validationSchema={getValidationFromFields(formFields)}
                onSubmit={(values)=>{
                    const apiPath = `${process.env.REACT_APP_API_URL}/settargetdate/${matterId}`;
                    axios.post(apiPath,values,{
                        timeout: 300000,
                        withCredentials:true
                    }).then((res) => {
                        const {
                            data:{
                                success=false
                            }
                        } = res;
                        if(success){
                            setShowDialogue(false)
                            loadData();
                        }
                        else{
                            cWarn('There was a problem saving the new contact');
                        }
                        
                    }).catch(err => {
                        cWarn(err); 
                    });
                }}
            >
                {props => (
                    <>
                        <DialogContent>
                            <form onSubmit={props.handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12} className="inputHolder">
                                        <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName={`targetDate`} variant="outlined" sx={{width:'100%'}}/>  
                                    </Grid>
                                </Grid>
                            </form>     
                        </DialogContent>
                        <DialogActions>
                        <Button variant="outlined" onClick={()=>{setShowDialogue(false)}}>{cancel}</Button>
                            <Button variant="outlined" onClick={props.handleSubmit}>{save}</Button>
                        </DialogActions>
                    </>
                )}
            </Formik>
        </Dialog>
    );

}