import React from "react";
import Box from "@mui/material/Box";
import * as Yup from "yup";



export const getAddressFields = (context,submitFunction) => {
    if(!context.isLoading){


        // 
    }
    const today = new Date();
    const date = new Date();
    const endDate = new Date(date.setMonth(date.getMonth()+1));

    return {
        'textSearch': {
            'label': 'filtersKeywordsPromptPc',
            'submitOnEnter': submitFunction,
            required:false
        },
        'selectMatterType': {
            'label': 'filterMatterType',
            'type':'select',
            'options':context.matterTypeOptions ? context.matterTypeOptions : [],
            required:false
        },
        'sourceOfBusiness': {
            'label': 'sourceOfBusiness',
            'type':'select',
            'options':context.sourceOfBusinessOptions ? context.sourceOfBusinessOptions : [],
            cmsOptions:true,
            required:false
        },
        'useDateRange':{
            'label': 'filterByDateLabel',
            'type':'checkbox',
            default:false,
            required:false
        },
        'fromDate': {
            'label': 'searchFromDate',
            'type':'datePicker',
            'default':today,
            'chip':false,
            required:false
        },
        'toDate': {
            'label': 'searchToDate',
            'type':'datePicker',
            'default':endDate,
            'chip':false,
            required:false
        },
        'user': {
            'label': 'assignedUsers',
            'default':'',
            'chip':false,
            required:false
        },

    };
};

export const getInitialFieldValues = (context,parameters) => {
    const fields = getAddressFields(context);
    const initialValues = {};
    const keys = Object.keys(fields);
    const values = Object.values(fields);
    for (let i = 0; i < keys.length; i += 1) {
        if(parameters.get(keys[i])){
            if(fields[keys[i]].type === 'checkbox'){
                initialValues[keys[i]] = (parameters.get(keys[i]) === 'true');
            }
            else if(fields[keys[i]].type === 'datePicker'){
                const dateParts = parameters.get(keys[i]).split('-');
                const val = new Date();
                val.setDate(dateParts[0]);
                val.setMonth(dateParts[1]-1);
                val.setUTCFullYear(dateParts[2]);
                initialValues[keys[i]] = val;
            }
            else{
                initialValues[keys[i]] = parameters.get(keys[i]);
            }

        }
        else{
            initialValues[keys[i]] = ('default' in values[i] ? values[i].default : '');
        }
    }
    context.softSetValues(initialValues);
    return initialValues;
}
