
const testLogAbility = () => {
    if(process.env.REACT_APP_ENV === 'dev'){
        return true;
    }
    if(localStorage.getItem('devMode') === 'on'){
        return true;
    }
    return false;
}


const noLog =  (msg) => {
    if(testLogAbility()){
        console.log(msg);
    }   
}


export const customLogger = testLogAbility() ? console.log.bind(window.console) : noLog

export const cLog = testLogAbility() ? console.log.bind(window.console) : noLog

export const customWarn = testLogAbility() ? console.log.bind(window.console) : noLog

export const cWarn = testLogAbility() ? console.log.bind(window.console) : noLog