import React, { useContext } from 'react';
import { BrandContext } from '../../../contexts/BrandContext';
import { Container } from '@mui/material';
import Page from '../../Page';
import MattersHeader from '../../ReportViews/Matters/MattersHeader';
import WidgetsContextProvider from './WidgetsContext';
import WidgetsInner from './WidgetsInner';
import { useParams } from 'react-router-dom';


export default function WidgetsOuter(props) {

    const brandContext = useContext(BrandContext);

    const {
        textContent:{
            privateClient:pageTitle=''
        }
    } = brandContext;

    const {matterId} = useParams();
    
    return (
        <>
            <Page title={pageTitle}>
                <Container maxWidth="xl">
                    <WidgetsContextProvider matterId={matterId} >
                        <MattersHeader title={pageTitle}/>
                        <WidgetsInner/>
                    </WidgetsContextProvider>
                </Container>
            </Page>
        </>
    );
}

