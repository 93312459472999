import axios from 'axios';

// ----------------------------------------------------------------------

const axiosInstance = axios.create();

/*
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    )
);
*/

axiosInstance.interceptors.request.use(
    config => {
        config.headers = {
            'Authorization': localStorage.getItem('accessToken')
        }
        return config;
    }
    ,
    error => {
        // console.log('this is an error');
        return Promise.reject(error)
    });

// Response interceptor for API calls
axiosInstance.interceptors.response.use(response =>response
    , (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const apiPath = `${process.env.REACT_APP_API_URL}/refreshtoken`;
            return axios.get(apiPath,{
                timeout: 30000,
                withCredentials:true})
                .then(res => {
                    const accessToken = res.data.token;
                    localStorage.setItem('accessToken', res.data.token);
                    axiosInstance.defaults.headers.common.Authorization = accessToken;
                    originalRequest.headers.Authorization = accessToken;
                    return axiosInstance(originalRequest);
                })
        }
        return Promise.reject(error);
    });
export default axiosInstance;
