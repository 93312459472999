import jwtDecode from 'jwt-decode';
import { createSlice } from '@reduxjs/toolkit';

// utils

import axios from '../../utils/axios';


// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  user: {}
};

const slice = createSlice({
  name: 'authDal',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    
    // INITIALISE
    getInitialize(state, action) {
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
    },

    // LOGIN
    loginSuccess(state, action) {
       state.isAuthenticated = true;
      state.user = action.payload.user;
    },

    // LOGOUT
    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.user = null;
    }
  }
});





// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  
  return decoded.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = accessToken;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ----------------------------------------------------------------------

export function login({data}) {
  return async (dispatch) => {
    const accessToken = data.token;

    window.localStorage.setItem('accessToken', accessToken);


    setSession(accessToken);
    dispatch(slice.actions.getInitialize({
      isAuthenticated: true,
      user: {
        'name':'user'
      }
    }));
    
  }

}

// ----------------------------------------------------------------------

export function logout() {
  return async (dispatch) => {
    setSession(null);
    window.localStorage.removeItem('accessToken');
    dispatch(slice.actions.logoutSuccess());
  };
}

// ----------------------------------------------------------------------

export function testAuth(){
  const accessToken = window.localStorage.getItem('accessToken');
  if (accessToken && isValidToken(accessToken)){
    return true;
  }
  return false;
}

export function getInitialize() {
  return async (dispatch) => {
   dispatch(slice.actions.startLoading());

    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)){
        setSession(accessToken);
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: true,
            user: {}
          })
        );



      } else {
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: false,
            user: null
          })
        );
      }
    } catch (error) {
      console.error(error);
      dispatch(
        slice.actions.getInitialize({
          isAuthenticated: false,
          user: null
        })
      );
    }

  };
}
