import React, { useContext } from 'react';
import { BrandContext } from '../../../contexts/BrandContext';
import { Box, Button, Card, Grid, Stack, Typography } from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import BasicInputField from '../../form/BasicInputField';
import ContactCreate from '../../ContactCreation/ContactCreate';
import { CreateMatterContext } from './CreateMatterContext';
import { LoadingBox } from '../../UniversalComponents';

export default function CreateMatterInner(props) {
     
    const brandContext = useContext(BrandContext);

    const createMatterContext = useContext(CreateMatterContext);

    const{
        isLoading
    } = createMatterContext;

    if(isLoading){
        return (<LoadingBox/>)
    }

    const {
        textContent:{
            createMatterButton='',
            saveProspect='',
            matterInformationTitle='',
            saveProspectSendDraft='',

        }
    } = brandContext;

    const {
        config:{
            createFields:formFields,
            contactFields
        },
        submitNewUnit:createMethod
    } = createMatterContext;

   

    const initialValues = () => {
        const fields = formFields;

        const initialValues = {};
        const keys = Object.keys(fields);
        const values = Object.values(fields);
        for (let i = 0; i < keys.length; i += 1) {
            initialValues[keys[i]] = ('default' in values[i] ? values[i].default : '');
        }

        for(let i=0; i<10; i+=1){            
            const keysArray = Object.keys(contactFields.contactFields);
            const valuesArray = Object.values(contactFields.contactFields);
            for(let j=-0; j<keysArray.length; j+=1){
                initialValues[`${keysArray[j]}_${i}`] = ('default' in valuesArray[j] ? valuesArray[j].default : 'beans');
            }
        }

        return initialValues;
    }

    const validationSchema = () => {
        const validationSchema = Yup.object().shape({
            c_firstName_0: Yup.string()
                .required('Required'),   
        });
        return validationSchema;
    }

    return (
        <>
            <Formik
                initialValues={initialValues()}
                validationSchema={validationSchema}
                onSubmit={(values)=>{
                    createMethod(values,true,false);
                }}
            >
                {props => (
                    <>
                        <Card className="formCard">
                            <ContactCreate formikProps={props} fieldConfig={contactFields} />
                        </Card>
                        <Card className="formCard" sx={{marginTop:10, marginBottom:20}}>
                            <Typography variant="h2">{matterInformationTitle}</Typography>
                            <Box sx={{marginTop:5}}>
                            <Stack alignItems="left" sx={{marginBottom:5}}>
                                    <form onSubmit={props.handleSubmit}>
                                        <Grid container >
                                            <Grid item xs={12} md={12} className="inputHolder">
                                                <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="matterType" variant="outlined" />  
                                            </Grid>
                                            <Grid item xs={12} md={12} className="inputHolder">
                                                <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="howDidYouHear" variant="outlined" />  
                                            </Grid>
                                            <Grid item xs={12} md={12} className="inputHolder">
                                                <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="feeEarner" variant="outlined" />  
                                            </Grid>
                                        </Grid>
                                    </form>
                                    
                                </Stack>
                                </Box>
                                <Box className="formFooterBox">
                                    <Button className="roundButton blueButton" onClick={()=>{
                                        createMethod(props.values,false,false);
                                    }}>
                                        {createMatterButton}
                                    </Button>
                                    <Button className="roundButton whiteButton" sx={{marginLeft:'10px'}} onClick={()=>{
                                        createMethod(props.values,true,false);
                                    }}>
                                        {saveProspect}
                                    </Button>
                                </Box>
                        </Card>
                    </>
                )}

            </Formik>
        </>
    );
}

