import React from "react";
import { useNavigate } from "react-router";
import ValuationsContextProvider from "./ValuationsContext";
import ResultsPage from "./ResultsPage";


export default function ArchivedResults(){
    const history = useNavigate();
    return (

        <ValuationsContextProvider history={history} archived>
            <ResultsPage/>
        </ValuationsContextProvider>
    );
}