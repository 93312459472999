import { Description, FileCopy, FormatListNumbered, Group, ListAlt, Mail, Storage } from '@mui/icons-material';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { lazy } from 'react';


export const widgetMap = {
    Inbox: {
        title:'widgetTitleInbox',
        icon:<Mail/>,
        component: lazy(() => import('../../Widgets/Inbox/Inbox'))
    },
    AllTasks: {
        title:'widgetTitleAllTasks',
        icon:<ListAlt/>,
        component: lazy(() => import('../../Widgets/Tasks/AllTasks'))
    },
    YourTasks: {
        title:'widgetTitleYourTasks',
        icon:<ListAlt/>,
        component: lazy(() => import('../../Widgets/Tasks/YourTasks'))
    }
};

export const leftWidgets = [];


export const tabs = [
    {
        urlKey:'all',
        nameKey:'all',
        widgets:['Inbox','AllTasks','YourTasks']
    },
];


