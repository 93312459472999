import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack } from "@mui/material";
import { BrandContext } from '../../contexts/BrandContext';
import { Formik } from 'formik';
import axios from '../../utils/axios';
import { cWarn } from '../CustomLogger';
import BasicInputField from '../form/BasicInputField';
import { getValidationFromFields, initialValues } from '../UniversalComponents';
import { DragContext } from '../Widgets/DragContext';
import FileBlockContextProvider from './Documents/FileBlockContext';
import FileDialogue from './Documents/FileDialogue';



export default function CreateLetterDialogue(props){
    
    const {
        context,
        matterId
    } = props;
    const flag = 'showCreateLetterDialogue';
    const {
        [flag]:showState=false,
        hideDialogue,
        accessScope
    } = context;

    const dragContext = useContext(DragContext);

    const {
        notifyDrop
    } = dragContext;

    const [showFileDialogue,setShowFileDialogue] = useState(false);
    const [doc,setDoc] = useState(null);

    const DialogueInner = () => {

        const brandContext = useContext(BrandContext);        

        const  {
            textContent:{
                createLetterTitle:title = '',
                save='',
                cancel=''
            }
        } = brandContext;

        const [isLoaded,setIsLoaded] = useState(false);
        const [formFields,setFormFields] = useState([]);

        useEffect(() => {
            // Update the document title using the browser API
            if(!isLoaded){
                const apiPath = `${process.env.REACT_APP_API_URL}/getletterfields/${matterId}/${accessScope}`;

                axios.get(apiPath,{
                    timeout: 300000,
                    withCredentials:true
                }).then(res => {

                    const {
                        data:{
                            fields:dataFormFields={}
                        }
                    } = res;


                    dataFormFields.styles.optionTemplate = (option) => {
                        if(option){
                            const {label=''} = option
                            return label;
                        }
                        return ''
                    };

                    setFormFields(dataFormFields)
                    setIsLoaded(true);
                    

                }).catch(err => {
                cWarn(err); 
                });

            }
        },[setIsLoaded,isLoaded]);

        const openRedirect = (path,docId) => {
            const apiPath = `${process.env.REACT_APP_API_URL}/${path}/${docId}`;
            axios.get(apiPath,{
                timeout: 300000,
                withCredentials:true,
            }).then(res => {
                const{
                    data:{
                        redirect=''
                    }
                } = res;
                if(redirect){
                    window.open(redirect);
                }
                
                
            }).catch(err => {
                cWarn(err);
            });
        }

        const handleSave = (values) => {
            const apiPath = `${process.env.REACT_APP_API_URL}/savenewletter/${matterId}/${accessScope}`;
            axios.post(apiPath,values,{
                timeout: 300000,
                withCredentials:true
            }).then((res) => {

                const {
                    data:{
                        documentData=null
                    }
                } = res;



                if(documentData !== null){
                    setDoc(documentData);
                    openRedirect('doc/365open',documentData.id);
                    setShowFileDialogue(true);
                }

                hideDialogue(flag);            
                notifyDrop();
            }).catch(err => {
            cWarn(err); 
            });
        }

        const setFileName = (e,props)=>{
            
            
            
            
            
            const options = formFields.styles.options;
                                                  
            const val = e.value;
            for(let i=0; i<options.length; i+=1){
                const {
                    value,
                    preamble='',
                    label
                } = options[i];
                console.log(`${label} - ${value} :: ${val}`);
                if(value === val){
                    console.log('matched');
                    props.setFieldValue('docName',label)
                    if(preamble){
                        props.setFieldValue('preamble',preamble.replace(/\n/g, '<br>'))
                    }
                    break;
                }
            }
        }

        return(
            <>
            <DialogTitle>{title}</DialogTitle>
            <Collapse in={isLoaded}>
                <Formik
                    initialValues={initialValues(formFields)}
                    validationSchema={getValidationFromFields(formFields)}
                    onSubmit={(values)=>{
                        handleSave(values);
                    }}
                >
                    {props => (
                        <>
                            <DialogContent>
                                <Stack alignItems="left" sx={{marginBottom:5}}>
                                    <form onSubmit={props.handleSubmit}>
                                        <Grid container >
                                            <Grid item xs={12} md={12} className="inputHolder">
                                                <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="styles" variant="outlined" onChange={(e)=>{setFileName(e,props);}} />  
                                                {props.values.styles && 
                                                <>
                                                    <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="docName" variant="outlined" />  
                                                    <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="preamble" variant="outlined" />  
                                                </>
                                                }
                                            </Grid>
                                        </Grid>
                                    </form>    
                                </Stack>                   
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={props.handleSubmit}>{save}</Button>
                                <Button onClick={()=>{hideDialogue(flag)}}>{cancel}</Button>
                            </DialogActions>
                        </>
                    )}
                </Formik>
            </Collapse>
            </>
        );
    }

    return (
        <>
        <Dialog open={showState} onClose={()=>{hideDialogue(flag)}} >
            <Box sx={{minWidth:600}}>
            {showState &&
                <DialogueInner/>
            }
            </Box>
        </Dialog>
        {showFileDialogue &&
            <FileBlockContextProvider doc={doc} sendToIcon sendToSortingOffice>
                <FileDialogue  showDialogue={showFileDialogue} setShowDialogue={setShowFileDialogue}/>
            </FileBlockContextProvider>
        }
        </>
    );
}

