import React, { useContext } from 'react';
import { Box, Card, CardHeader, Collapse } from "@mui/material";
import CustomMenuList from '../CustomMenuList';
import { WidgetContext } from './WidgetContext';



export default function BigWidget(props){

    const {
        title='',
        icon=null,
    } = props;
    
    const widgetContext = useContext(WidgetContext);

    const {
        isExpanded,
        toggleExpansion
    } = widgetContext;

    const {tools} = widgetContext;
    

    return (
            <Card className="bigWidget">
                <CardHeader 
                    avatar={icon} 
                    className={`bigWidgetHeader ${isExpanded && 'isOpen'}`} 
                    title={title} 
                    onClick={toggleExpansion}
                    action={tools.length > 0 && 
                        <CustomMenuList
                            size="medium"
                            options={tools}
                        />
                      }
                />
                <Collapse in={isExpanded}>
                    {isExpanded &&
                        <Box className="innerBigWidget">
                            {props.children}
                        </Box>
                    }
                </Collapse>
            </Card>
    );

}