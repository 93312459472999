import React, { useContext } from 'react';
import {useDraggable} from '@dnd-kit/core';
import { Box } from '@mui/material';
import { FileBlockContext } from './FileBlockContext';

export default function DraggableBlock(props) {

	const context = useContext(FileBlockContext);
	const {
		doc:{
			id=0
		}
	} = context;


	const {attributes, listeners, setNodeRef, transform} = useDraggable({
		id,

	});
	const style = transform ? {
		transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
	} : undefined;

	return (
		<Box ref={setNodeRef} style={style} {...listeners} {...attributes}>
			{props.children}
		</Box>
	);
}