import React, { useContext, useState } from 'react';
import { BrandContext } from '../../../contexts/BrandContext';
import { LoadingBox, formatAddressToString } from '../../UniversalComponents';
import { Box, Button, Typography } from '@mui/material';
import { leftWidgets, tabs, widgetMap } from './WidgetConfig';
import TabsContainer from '../../Widgets/TabsContainer';
import CreateLetterDialogue from '../../Utilities/CreateLetterDialogue';
import CreateDocumentDialogue from '../../Utilities/CreateDocumentDialogue';
import ArchiveMatterDialogue from '../../Utilities/ArchiveMatterDialogue';
import SendEmailDialogue from '../../Utilities/SendEmailDialogue';
import ValuationDateDialogue from '../../Utilities/ValuationDateDialogue';
import ValuationTypeDialogue from '../../ReportViews/Valuations/ValuationTypeDialogue';
import RelatedTransactions from '../../Utilities/RelatedTransactions';
import DeleteMatterDialogue from '../../Utilities/DeleteMatterDialogue';
import { MatterDetailsContext } from '../matters/MatterDetailsContext';


export default function ValuationDetailsInner() {
     
    const brandContext = useContext(BrandContext);

    const context = useContext(MatterDetailsContext); 
    const [showValuationDateDialogue,setShowValuationDateDialogue] = useState(false);
    const [showValuationTypeDialogue,setShowValuationTypeDialogue] = useState(false);
    const{
        isLoading,
    } = context;

    if(isLoading){
        return (<LoadingBox/>)
    }

    
    console.log(context.showDeleteMatterDialogue);
    const {
        textContent:{
            relatedTransaction='',
            refLabel='',
            emailTagLabel='',
            valuationDateTimeLabel='',
            proposedSaleOf='',
            knownAsLabel='',
            valuerLabel=''
        },
        textContent={}
    } = brandContext;

    const {
        details:{
            id='',
            client,
            case:cCase,
            estateAgencyData:{
                valuationType=null,
            },
            events=[],
            emailTag=''
        },
        userRoles=[]
    } = context;

    let fullName = '';
    let knownAs = '';
    if(client){
        try{
            if('primaryContact' in client){
                ({primaryContact:{
                    fullName,
                    salutation:knownAs='',
                }} = client);
            }   
        }
        catch(e){
            // cWarn(e)
        }
    }



    const formattedAddress = formatAddressToString(cCase);
    let valuationTypeString='';
    let valuationTypeId = null;
    if(valuationType){
        ({textKey:valuationTypeString,id:valuationTypeId} = valuationType);
    }
    
    let valuationDateFormatted = '';
    if(events.length > 0){
        const {startTime:valuationDate} = events[0];
        const dateObj = new Date(valuationDate);
        const options = { year: 'numeric', month: 'short', day: 'numeric' , hour: 'numeric', minute: 'numeric'};
        valuationDateFormatted = new Intl.DateTimeFormat('en-GB', options).format(dateObj);
    }
    let valuer = null;
    let valuerName = null;
    if(userRoles){
        if(userRoles.length > 0){
            for(let i=0; i<userRoles.length; i+=1){
                const {
                    roleType:{
                        id:roleTypeId=0
                    }
                } = userRoles[i];
                if(roleTypeId === 8){
                    valuer = userRoles[i];
                    break;
                }
            }
        }
    }
    if(valuer){
        ({
            bdUser:{
                fullName:valuerName
            }
        } = valuer);
    }
    

    return (
        <>
            <Box className="boxHeader" sx={{display:'flex'}}>
                <Box sx={{flexGrow:1}}>
                    <Typography>{refLabel} <strong>{id}</strong> | {emailTagLabel}<strong>{emailTag}</strong></Typography>
                    <Typography variant="h1" sx={{marginTop:3}}>{fullName} {knownAs && `(${knownAsLabel} ${knownAs})`}</Typography>
                    <Box sx={{paddingTop:1}}>
                        <Typography variant="h2Thin" >{proposedSaleOf} {formattedAddress}</Typography>
                    </Box>
                    <Box sx={{display:'flex', flexDirection:'row', alignItems:'center', marginTop:3}}>
                        <Box>
                            <Button sx={{marginTop:'20px', marginRight:1}} className="roundButton whiteButton" onClick={()=>{setShowValuationTypeDialogue(true)}}>{textContent[valuationTypeString]}</Button>
                        </Box>
                        {valuationTypeId !== 1 &&
                        <Box>
                            <Typography sx={{cursor:'pointer'}} onClick={()=>{setShowValuationDateDialogue(true)}}>{valuationDateTimeLabel} <Typography className="blueBold" component="span">{valuationDateFormatted}</Typography> </Typography>
                            {valuer &&
                                <Box>
                                <Typography>{valuerLabel} <Typography className="blueBold" component="span">{valuerName}</Typography></Typography>
                                </Box>
                            }
                        </Box>
                        }   
                        
                    </Box>
                </Box>
                <RelatedTransactions context={context} />
            </Box>
            <TabsContainer 
                    leftWidgets={leftWidgets}
                    widgetMap={widgetMap}
                    tabs={tabs}
                    context={context}
            >
                {showValuationDateDialogue &&
                    <ValuationDateDialogue context={context} showDialogue={showValuationDateDialogue} setShowDialogue={setShowValuationDateDialogue}/>
                }
                {showValuationTypeDialogue &&
                    <ValuationTypeDialogue context={context} showDialogue={showValuationTypeDialogue} setShowDialogue={setShowValuationTypeDialogue}/>
                }
                <ArchiveMatterDialogue context={context} matterId={id}/>
                <DeleteMatterDialogue context={context} matterId={id}/>
                <CreateLetterDialogue context={context} matterId={id}/>
                <SendEmailDialogue context={context} matterId={id}/>
                <CreateDocumentDialogue context={context} matterId={id}/>
            </TabsContainer>


          
        </>
    );
}

