import React from 'react';
import { Card, CardHeader } from "@mui/material";



export default function MiniWidget(props){

    const {
        title=''
    } = props;

    return (
            <Card className="miniWidget">
                <CardHeader className="miniWidgetHeader" title={title}/>
                {props.children}
            </Card>
    );

}