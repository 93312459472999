import React, { useContext, useState } from 'react';
import { BrandContext } from '../../../contexts/BrandContext';
import { Backdrop, Box, Button, Card, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import BasicInputField from '../../form/BasicInputField';
import ContactCreate from '../../ContactCreation/ContactCreate';
import { CreateValuationContext } from './CreateValuationContext';
import { LoadingBox, getValidationFromFields } from '../../UniversalComponents';
import NewValuationScheduleAppt from './NewValuationScheduleAppt';
import { selectAddressAndMoveOn } from '../../Utilities/AddressAutoCompletion';
import { ContactSupportOutlined } from '@mui/icons-material';
import { cLog } from '../../CustomLogger';

export default function CreateValuationInner(props) {
     
    const brandContext = useContext(BrandContext);

    const createValuationContext = useContext(CreateValuationContext);

    const [showAppointmentDialog,setShowAppointmentDialog] = useState(false);

    const{
        isLoading
    } = createValuationContext;

    if(isLoading){
        return (<LoadingBox/>)
    }

    const {
        textContent:{
            saveDetailsButton='',
            propertyInformationTitle='',
            saveScheduleValuation=''

        }
    } = brandContext;

    const {
        config:{
            createFields:formFields,
            contactFields
        },
        submitNewUnit:createMethod,
        showWorking=false
    } = createValuationContext;


    formFields.startTyping.optionTemplate = (option) => `${option.address}`;


    formFields.district.optionTemplate = (option) => {
        if(option){
            const {label=''} = option
            return label;
        }
        return ''
    };

    const initialValues = () => {
        const fields = formFields;

        const initialValues = {};
        const keys = Object.keys(fields);
        const values = Object.values(fields);
        for (let i = 0; i < keys.length; i += 1) {
            initialValues[keys[i]] = ('default' in values[i] ? values[i].default : '');
        }

        for(let i=0; i<10; i+=1){            
            const keysArray = Object.keys(contactFields.contactFields);
            const valuesArray = Object.values(contactFields.contactFields);
            for(let j=-0; j<keysArray.length; j+=1){
                initialValues[`${keysArray[j]}_${i}`] = ('default' in valuesArray[j] ? valuesArray[j].default : 'beans');
            }
        }


        return initialValues;
    }

    const setAddress = (props,formik) => {
        
        const map = {
            houseName:'houseNo',
            houseNumber:'houseNo',
            postcode:'postcode',
            street:'streetName',
            town:'town',
        }
        const keys = Object.keys(map);
        const values = Object.values(map);
        for(let i=0; i<values.length; i+=1){
            if(props[keys[i]]){
                formik.setFieldValue(values[i],props[keys[i]]);
            }
        }
        console.log(formik.values);
    }

    return (
        <>
            <Formik
            initialValues={initialValues()}
            validationSchema={getValidationFromFields(formFields)}
            onSubmit={(values)=>{
                createMethod(values,false);
            }}
        >
            {props => (
                <>
                    <Card className="formCard">
                        <ContactCreate formikProps={props} fieldConfig={contactFields} noDob/>
                    </Card>
                    <Card className="formCard" sx={{marginTop:10, marginBottom:20}}>
                        <Typography variant="h2">{propertyInformationTitle}</Typography>
                        <Box sx={{marginTop:5}}>
                        <Stack alignItems="left" sx={{marginBottom:5}}>
                                    <Grid container >
                                    <Grid item xs={12} md={12} className="inputHolder">
                                        <BasicInputField  formik={props} formFields={formFields} fieldName="sameAsContact" variant="outlined" />  
                                    </Grid>
                                    {!props.values.sameAsContact &&
                                    <>
                                        <Grid item xs={12} md={12} className="inputHolder">
                                            <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="startTyping" variant="outlined" onChange={(value)=>{selectAddressAndMoveOn(value,props,setAddress)}}  />  
                                        </Grid>
                                        <Grid item xs={12} md={12} className="inputHolder">
                                            <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="houseNo" variant="outlined" />  
                                        </Grid>
                                        <Grid item xs={12} md={12} className="inputHolder">
                                            <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="streetName" variant="outlined" />  
                                        </Grid>
                                        <Grid item xs={12} md={12} className="inputHolder">
                                            <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="town" variant="outlined" />  
                                        </Grid>
                                        <Grid item xs={12} md={12} className="inputHolder">
                                            <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="postcode" variant="outlined" />  
                                        </Grid>
                                    </>
                                    }
                                    <Grid item xs={12} md={12} className="inputHolder">
                                        <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="district" variant="outlined" />  
                                    </Grid>
                                    <Grid item xs={12} md={12} className="inputHolder">
                                        <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="noBedrooms" variant="outlined" />  
                                    </Grid>
                                    <Grid item xs={12} md={12} className="inputHolder">
                                        <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="propertyType" variant="outlined" />  
                                    </Grid>
                                    <Grid item xs={12} md={12} className="inputHolder">
                                        <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="valuationType" variant="outlined" />  
                                    </Grid>
                                    <Grid item xs={12} md={12} className="inputHolder">
                                        <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="proposedTransactionType" variant="outlined" />  
                                    </Grid>
                                    <Grid item xs={12} md={12} className="inputHolder">
                                        <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="reasonsForSelling" variant="outlined" />  
                                    </Grid>
                                    <Grid item xs={12} md={12} className="inputHolder">
                                        <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="additionalNotes" variant="outlined" />  
                                    </Grid>
                                    <Grid item xs={12} md={12} className="inputHolder">
                                        <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="existingMortgage" variant="outlined" />  
                                    </Grid>
                                    <Grid item xs={12} md={12} className="inputHolder">
                                        <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="existingSecondMortgage" variant="outlined" />  
                                    </Grid>
                                    <Grid item xs={12} md={12} className="inputHolder">
                                        <BasicInputField  formik={props} formFields={formFields} fieldName="goldenShare" />  
                                    </Grid>
                                    <Grid item xs={12} md={12} className="inputHolder">
                                        <BasicInputField  formik={props} formFields={formFields} fieldName="sharedEquity" />  
                                    </Grid>
                                    <Grid item xs={12} md={12} className="inputHolder">
                                        <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="estimatedPurchasePrice" variant="outlined" />  
                                    </Grid>
                                    

                                    <Grid item xs={12} md={12} className="inputHolder">
                                        <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName="howDidYouHear" variant="outlined" />  
                                    </Grid>


                                    </Grid>
                                
                            </Stack>
                            </Box>
                            <Box className="formFooterBox">
                                <Button className="roundButton blueButton" onClick={()=>{
                                    cLog(props.errors);
                                    props.handleSubmit();
                                }}>
                                    {saveDetailsButton}
                                </Button>
                                {props.values.valuationType !== 1 &&
                                <Button disabled={!props.isValid} className="roundButton whiteButton" sx={{marginLeft:'10px'}} onClick={()=>{
                                        cLog(props.errors);
                                        console.log(props.isValid);
                                        props.validateForm().then((errors) => {
                                            if (Object.keys(errors).length === 0) {
                                                console.log('Validation Passed');
                                                setShowAppointmentDialog(true);
                                               
                                            } else {
                                                const allFields = Object.keys(errors).reduce((acc, field) => {
                                                    acc[field] = true;
                                                    return acc;
                                                  }, {});
                                                  props.setTouched(allFields, true);
                                            }
                                        });
                                        
                                    }}>
                                        {saveScheduleValuation}
                                </Button>
                                }
                            </Box>
                    </Card>
                    {showAppointmentDialog &&
                        <NewValuationScheduleAppt
                            showDialogue={showAppointmentDialog}
                            setShowDialogue={setShowAppointmentDialog}
                            formFields={formFields}
                            formik={props}
                        />
                    }
                </>
            )}

        </Formik>
        {showWorking &&
            <Backdrop
                sx={{ color: '#fff', zIndex: 10000}}
                open={true}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
        }
</>
    );
}

