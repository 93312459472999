import React, { useContext } from "react";
import { Box, Button, Typography } from "@mui/material";

import { BrandContext } from "../../contexts/BrandContext";




export default function FileUpload(props){
    const brandContext = useContext(BrandContext);

    const {
        textContent
    } = brandContext;
    
    const {
        formFields=[],
        fieldName='',
        formik
    } = props;

    if(!(fieldName in formFields)){
        return null;
    }
    
    const fieldProps = formFields[fieldName];
    console.log(fieldProps);
    const {
        label:labelKey='',
        multiple=false
    } = fieldProps;

    const label = textContent[labelKey];

    let filesList = [];
    if(formik.values[fieldName] instanceof FileList){
        filesList = Array.from(formik.values[fieldName]);
    }
    
    
    let errorText = '';
    if(formik.errors[fieldName]){
        errorText = formik.errors[fieldName];
    }

    return(
        <Box>
            <Button
                variant="contained"
                component="label"
                sx={{width:'100%'}}
            >
                {label}
                <input id={props.fieldName} multiple={multiple} name={props.fieldName} type="file" hidden onChange={(event) => {
                    formik.setFieldValue(fieldName, event.currentTarget.files);
                }} />
            </Button>
            <Box >
                {(!errorText && (filesList.length > 0)) &&
                    <>
                        {filesList.map((file,index)=>{
                            const{
                                name:fileName=''
                            } = file;
                            return (
                                <Typography key={index} className="smallText" sx={{wordWrap:'break-word'}}>{fileName}</Typography>
                            );
                        })}
                    </>
                }
                {errorText && 
                    <Typography sx={{color:'#FF0000'}}>{errorText}</Typography>
                }
            </Box>
        </Box>
    );

}