import React, { useContext } from 'react';
import { BrandContext } from '../../../contexts/BrandContext';
import { Container } from '@mui/material';
import Page from '../../Page';
import ContactsHeader from '../../ReportViews/Contacts/ContactsHeader';
import ContactDetailsContextProvider from './ContactDetailsContext';
import ContactDetailsInner from './ContactDetailsInner';
import { useParams } from 'react-router-dom';


export default function ContactDetails(props) {

    const brandContext = useContext(BrandContext);

    const {
        textContent:{
            privateClient:pageTitle=''
        }
    } = brandContext;

    const {contactId} = useParams();
    
    return (
        <>
            <Page title={pageTitle}>
                <Container maxWidth="xl">
                    <ContactDetailsContextProvider contactId={contactId} >
                        <ContactsHeader title={pageTitle}/>
                        <ContactDetailsInner/>
                    </ContactDetailsContextProvider>
                </Container>
            </Page>
        </>
    );
}

