
import React, { Suspense, useContext } from 'react';
import { LoadingBox } from '../UniversalComponents';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";

import { BrandContext } from '../../contexts/BrandContext';
import MiniWidget from './BlueWidgets/MiniWidget';
import BigWidget from './BigWidget';
import WidgetContextProvider from './WidgetContext';
import { DndContext, DragOverlay, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import ArticleIcon from '@mui/icons-material/Article';
import DragContextProvider, { DragContext } from './DragContext';


export default function TabsContainerOuter(props){
    
    return (
    <DragContextProvider>
        <TabsContainer {...props}/>
    </DragContextProvider>
    );
}

const TabsContainer = (props) => {

    const brandContext = useContext(BrandContext);
    const navigate = useNavigate();
    const { tabKey='' } = useParams();

    const dragContext = useContext(DragContext);

    const {
        handleDrop,
        handleDragStart,
        isDragging
    } = dragContext;

    const {
        textContent
    } = brandContext;

    const {
        tabs,
        leftWidgets,
        widgetMap,
        context:{
            basePath
        },
        context
    } = props;

    let activeTabIndex=0;
    if(tabKey){
        for(let i=0; i< tabs.length; i+=1){
            if(tabKey === tabs[i]['urlKey']){
                activeTabIndex=i;
                
                break;
            }
        }
    }
    const activeTab = tabs[activeTabIndex];
    const {widgets} = activeTab;

    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

        
    const mouseSensor = useSensor(MouseSensor, {
        activationConstraint: {
        distance: 10, // Enable sort function when dragging 10px
        },
    })
    
    const sensors = useSensors(mouseSensor)  
    
    const hasLeftWidgets = leftWidgets.length > 0;
    const hasTabs = tabs.length > 1;

    return (
        <DndContext sensors={sensors} onDragStart={handleDragStart} onDragEnd={handleDrop}>
            <DragOverlay>
                {isDragging ? (
                <Box className="thumbBlock" sx={{display:'flex', height:'100px', alignItems:'center',justifyContent:'center'  }}><ArticleIcon style={{width:50, height:50}}/></Box>
                ): null}
            </DragOverlay>
            <Grid container spacing={2} className="sectionContainer" sx={{marginTop:3}}>
                {hasLeftWidgets &&
                <Grid item xs={12} md={3} className="leftWidgets">
                    {leftWidgets.map((widgetKey,index)=>{
                        const {
                            title:titleKey='',
                            icon=null,
                            component:Widget
                        } = widgetMap[widgetKey];

                        const title=textContent[titleKey];

                        return (
                            <WidgetContextProvider key={index}>
                                <MiniWidget title={title} icon={icon}>
                                    <Suspense fallback={<LoadingBox />}>
                                        <Widget context={context} />
                                    </Suspense>
                                </MiniWidget>
                            </WidgetContextProvider>
                        );
                    })}
                </Grid>
                }
                <Grid item xs={12} md={hasLeftWidgets ? 9 : 12} className="sectionMain">
                    {hasTabs && 
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom:4 }}>
                        <Tabs value={activeTabIndex} className="widgetTabs" variant="fullWidth">
                            {tabs.map((tab,index) => (
                                <Tab 
                                    key={index}
                                    className="buttonTab"
                                    component="a"
                                    label={textContent[tab.nameKey]} 
                                    onClick={()=>{
                                        navigate(`/${basePath}${tab.urlKey}`)
                                    }}
                                    {...a11yProps(index)} 
                                />    
                            ))}
                        </Tabs>
                    </Box>
                    }
                    <Box>
                        {widgets.map((widgetKey,index)=>{
                            const {
                                title:titleKey='',
                                icon=null,
                                component:Widget
                            } = widgetMap[widgetKey];

                            const title=textContent[titleKey];
                            return (
                                <WidgetContextProvider key={index} activeTab={activeTab} widgetKey={widgetKey}>
                                    <BigWidget title={title} icon={icon}>
                                        <Suspense fallback={<LoadingBox />}>
                                            <Widget context={context} />
                                        </Suspense>
                                    </BigWidget>
                                </WidgetContextProvider>
                            );
                        })}
                    </Box>
                </Grid>
            </Grid>
            {props.children}
        </DndContext>
    );
};