import React, { createContext } from 'react';
import axios from '../../../utils/axios';
import { cLog } from '../../CustomLogger';

export const MattersContext = createContext();

class MattersContextProvider extends React.Component {

    constructor(props){
        super(props);

        const queryString = window.location.search;
        const parameters = new URLSearchParams(queryString);


        const obj = {
            searchTerms:{},
            isLoading: true,
            showTimeEdit:false,
            results:[],
            searchConfig:{
                p:parameters.get('p') ? Number(parameters.get('p')) : 0,
                r:parameters.get('r') ? Number(parameters.get('r')) : 100,
            },
            umEditUnit:{},
            umEdit:false,
            umToggle:false,
            umWorking:false            
        };
        this.travelCo = props.travelCo;
        this.state = obj;
        this.formFields = false;
        this.history = props.history;

    }

    componentDidMount() {
        this.loadData();
    }


    setFormFields(formFields){
        this.formFields = formFields;
    }

    setResPage(p){
        /* eslint-disable */
        this.state.searchConfig.p = p;
        /* eslint-enable */
        this.updateSearch(false);
    }

    setResSize(r){
        /* eslint-disable */
        this.state.searchConfig.r = r;
        /* eslint-enable */
        this.updateSearch(false);
    }


    softSetValues(values){
        if(Object.keys(this.state.searchTerms).length === 0){
            /* eslint-disable */
            this.state.searchTerms = values;
             /* eslint-enable */
        }
    }

    updateSearch(resetPagination=true){
        
        cLog('we are updating the search');
        if(resetPagination){
            /* eslint-disable */
            this.state.searchConfig.p = 0;
            /* eslint-enable */
        }
        
        let queryString = Object.keys(this.state.searchTerms).map((key) => {
            if(this.formFields[key].type === 'datePicker'){
                const date = this.state.searchTerms[key];
                const dateFormat = `${date.getDate()}-${date.getMonth()+1}-${date.getUTCFullYear()}`;
                return `${key}=${dateFormat}&`
            }
            if(this.state.searchTerms[key]){
                return `${key}=${this.state.searchTerms[key]}&`
            }
            if(this.formFields[key].type === 'checkbox'){
                return `${key}=false&`
            }
            return '';
        }).join('');
        queryString = `${queryString}p=${this.state.searchConfig.p}&r=${this.state.searchConfig.r}&`;
        queryString = queryString.substr(0,queryString.length-1);
        this.history(`${queryString && '?'}${queryString}`);
        this.loadData();
    }

   
    


    loadData(){
        this.setState({
            isLoading:true
        });

        const queryString = window.location.search;


        let apiPath = `${process.env.REACT_APP_API_URL}/contacts/${queryString}`;

        if(this.props.archived){
            apiPath = `${process.env.REACT_APP_API_URL}/contacts/archived/${queryString}`;
        }

        if(this.props.prospects){
            apiPath = `${process.env.REACT_APP_API_URL}/contacts/prospects/${queryString}`;
        }

        

        axios.get(apiPath,{
            timeout: 300000,
            withCredentials:true
        }).then(res => {

            this.setState({
                matterTypeOptions:res.data.matterTypeOptions,
                sourceOfBusinessOptions:res.data.sourceOfBusinessOptions,
                isLoading:false,
                results:res.data.results,
                resTotal:res.data.resTotal,
            })


        }).catch(err => {
            // console.log('caught the error');
            // console.log(err);
            this.setState({

                isLoading:false,
                resTotal:0,
                results:[]
            });
        });
    }



    render(){
        return (
            <MattersContext.Provider value={{
                ...this.state,
                loadData: this.loadData.bind(this),
                setState: this.setState.bind(this),
                updateSearch:this.updateSearch.bind(this),
                setFormFields:this.setFormFields.bind(this),
                setResPage:this.setResPage.bind(this),
                setResSize:this.setResSize.bind(this),
                softSetValues:this.softSetValues.bind(this)
                
            }}>
                {this.props.children}
            </MattersContext.Provider>
        );
    }


}
export default MattersContextProvider;