import React, { useContext, useState } from 'react';
import { BrandContext } from '../../../contexts/BrandContext';
import { LoadingBox } from '../../UniversalComponents';
import { ContactDetailsContext } from './ContactDetailsContext';
import { Box, Button, Paper, Typography } from '@mui/material';
import { leftWidgets, tabs, widgetMap } from './WidgetConfig';
import TabsContainer from '../../Widgets/TabsContainer';
import TargetDateDialogue from '../../Utilities/TargetDateDialogue';
// import ArchiveContactDialogue from '../../Utilities/ArchiveContactDialogue';
import axios from '../../../utils/axios';
import { cWarn } from '../../CustomLogger';

export default function ContactDetailsInner(props) {
     
    const brandContext = useContext(BrandContext);

    const context = useContext(ContactDetailsContext); 

    const{
        isLoading,
    } = context;
    const [showTargetDateDialogue,setShowTargetDateDialogue] = useState(false);

    if(isLoading){
        return (<LoadingBox/>)
    }

    const {
        textContent:{
            relatedTransaction='',
            refLabel='',
            emailTagLabel='',
        }
    } = brandContext;

    const {
        details:{
            id,
            fullName,
            emailTag=''
        }
    } = context;



    return (
        <>
            <Box className="boxHeader" sx={{display:'flex'}}>
                <Box sx={{flexGrow:1}}>
                    <Typography>{refLabel} <strong>{id}</strong> | {emailTagLabel}<strong>{emailTag}</strong></Typography>
                    <Typography variant="h1" sx={{marginTop:3}}>{fullName}</Typography>
                </Box>
                <Paper sx={{padding:2}}>
                    <Typography variant="h3">{relatedTransaction}</Typography>
                </Paper>
          </Box>
          <TabsContainer 
                leftWidgets={leftWidgets}
                widgetMap={widgetMap}
                tabs={tabs}
                context={context}
          >
            {showTargetDateDialogue &&
                <TargetDateDialogue context={context} showDialogue={showTargetDateDialogue} setShowDialogue={setShowTargetDateDialogue}/>
            }

            
        </TabsContainer>
            


          
        </>
    );
}

