import React, { createContext } from 'react';
import axios from '../../../utils/axios';

export const CreateValuationContext = createContext();

class CreateValuationContextProvider extends React.Component {

    constructor(props){
        super(props);

        const obj = {
            isLoading: true,
            hasUpdatedSavedSearch:0,
            showWorking:false
        };

        this.state={...obj};
    }

    componentDidMount() {
        this.loadFormConfig();
    }
    


    loadFormConfig(){
        this.setState({
            isLoading:true
        });

        const queryString = window.location.search;

        const apiPath = `${process.env.REACT_APP_API_URL}/createvaluationconfig/${queryString}`;

        axios.get(apiPath,{
            timeout: 300000,
            withCredentials:true
        }).then(res => {

            const {
                data={}
            } = res;

            this.setState({
                isLoading:false,
                config:data,
            })


        }).catch(err => {
            // console.log('caught the error');
            // console.log(err);
            this.setState({
                isLoading:false,
                resTotal:0,
                results:[]
            });
        });
    }


    submitNewUnit(values,scheduleAppointment){

        const apiPath = `${process.env.REACT_APP_API_URL}/createnewvaluation`;
        this.setState({
            showWorking:true
        })
        if(scheduleAppointment){
            values.scheduleAppointment = true;
        }

        axios.post(apiPath,values,{
            timeout: 30000,
            withCredentials:true
        }).then((res) => {

            const {
                data:{
                    success,
                    newId
                }
            } = res;
            if(success){
                window.location.href = `/valuation/${newId}`;
            }
            this.setState({
                showWorking:false
            })
            
        }).catch(() => {
            this.setState({
                savingFare:false,
                saveBookingSuccess : false
            })
        });
    }



    render(){
        return (
            <CreateValuationContext.Provider value={{
                ...this.state,
                loadFormConfig: this.loadFormConfig.bind(this),
                setState: this.setState.bind(this),            
                submitNewUnit: this.submitNewUnit.bind(this)    
            }}>
                {this.props.children}
            </CreateValuationContext.Provider>
        );
    }


}
export default CreateValuationContextProvider;