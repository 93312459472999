
import React, { useContext } from "react";
import {
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select
} from "@mui/material";






import { BrandContext } from "../../contexts/BrandContext";

import 'dayjs/locale/en-gb';
import { cLog } from "../CustomLogger";

export default function BasicSelect (props){

    const brandContext = useContext(BrandContext);

    const {
        textContent
    } = brandContext;

    const {
        fieldName,
        formFields,
        formik:{
            values,
            errors,
            touched
        }
    } = props;

    const defaultField = {
        submitOnEnter: false
    };

    if(!(fieldName in formFields)){
        cLog(`${fieldName} not found in fields.`);
        return null;
    }

    const runningField = {defaultField, ...formFields[fieldName]};

    const extendProps = {...props};
    const filterFields = ['formik','formFields','fieldName','onChange'];
    filterFields.forEach(key => delete extendProps[key]);

    const {onChange:additionalOnChange} = props;

    let multiple = false;
    if('multiple' in runningField){
        if(runningField.multiple){
            multiple = true;
        }
    }

    

    return  (
        <FormControl {...extendProps} className="standardSelect">
            <InputLabel id={`${fieldName}_label`}>{textContent[runningField.label]}</InputLabel>
            <Select
                select
                
                id={fieldName}
                value={values[fieldName]}
                onChange={(e) => {
                    props.formik.setFieldValue(fieldName, e.target.value)
                    if(typeof additionalOnChange === 'function'){
                        additionalOnChange(e)
                    }
                    
                }}
                helperText={errors[props.fieldName]}
                input={<OutlinedInput label={textContent[runningField.label]} />}
                error={Boolean(touched[fieldName] && errors[fieldName])}
                {...extendProps}
                multiple={multiple}
            >
                {runningField.options.map((option) => {
                    
                    const {
                        hide=false
                    } = option;
                    if(!hide){
                        return(
                            <MenuItem key={option.value} value={option.value}>
                                {
                                    runningField.cmsOptions ?
                                        textContent[option.label]
                                        :
                                        option.label
                                }
                            </MenuItem>
                        );
                    }
                    return null;
                })}
            </Select>
        </FormControl>
    );
}