import React, { useContext } from 'react';
import { BrandContext } from '../../../contexts/BrandContext';
import { Container } from '@mui/material';
import Page from '../../Page';
import ValuationsHeader from '../../ReportViews/Valuations/ValuationsHeader';
import ValuationDetailsInner from './ValuationDetailsInner';
import { useParams } from 'react-router-dom';
import WidgetsContextProvider from '../pcdashboard/WidgetsContext';
import { ProfileContext } from '../../../contexts/ProfileContext';
import MatterDetailsContextProvider from '../matters/MatterDetailsContext';


export default function ValuationDetails() {

    const brandContext = useContext(BrandContext);
    const profileContext = useContext(ProfileContext);

    const {
        textContent:{
            valuationDetailTitle:pageTitle=''
        }
    } = brandContext;

    const {valuationId} = useParams();
    
    return (
        <>
            <Page title={pageTitle}>
                <Container maxWidth="xl">
                    <MatterDetailsContextProvider matterId={valuationId} profile={profileContext.profile} mode="valuation">
                        <WidgetsContextProvider matterId={valuationId} >
                            <ValuationsHeader/>
                            <ValuationDetailsInner/>
                        </WidgetsContextProvider>
                    </MatterDetailsContextProvider>
                </Container>
            </Page>
        </>
    );
}

