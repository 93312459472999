import React, {createContext} from 'react';
import axios from "../utils/axios";
import { cWarn } from '../components/CustomLogger';

export const ProfileContext = createContext();

class ProfileContextProvider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            hasFailed:false,
            profile: ''

        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData(){

        this.setState({
            hasFailed:false,
            isLoading:true
        });
        const apiPath = `${process.env.REACT_APP_API_URL}/profile`;

        axios.get(apiPath,{
            timeout: 30000,
            withCredentials:true
        }).then(res => {
            this.setState({
                isLoading:false,
                profile:res.data
            })
        }).catch(err => {
            this.setState({
                hasFailed:true
            });
           // console.log('caught the error');
           cWarn(err);
        });

    }


    render() {
        return (
            <ProfileContext.Provider value={{
                ...this.state,
                loadData: this.loadData.bind(this)
            }}>
                {this.props.children}
            </ProfileContext.Provider>
        );
    }

}
export default ProfileContextProvider;