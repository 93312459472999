import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import throttle from 'lodash/throttle';
import axios from "../../utils/axios";

import { BrandContext } from '../../contexts/BrandContext';
import { Chip } from '@mui/material';

export default function ComboBox(props) {

    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const brandContext = useContext(BrandContext);


    const {
        textContent
    } = brandContext;

    const {
        fieldName,
        formFields,
        formik:{
            values,
            errors
        }
    } = props;
	const defaultField = {
        submitOnEnter: false
    };
	const runningField = {defaultField, ...formFields[fieldName]};
	const {
        options,
        useCms=true,
        multiple=true,
        label
    } = runningField;

    

	let hasValue = false;
	if(values[props.fieldName]){
		if(values[props.fieldName] !== null){
			if(Array.isArray(values[props.fieldName])){
				if(values[props.fieldName].length > 0){
					hasValue = true;
				}
			}
			else{
				hasValue = true;
			}
		}
	}
	


    
	const [loaded, setLoaded] = useState(hasValue ? false : true);
	console.log(values[props.fieldName]);
	const [defaultValue, setDefaultValue] = useState(hasValue ? values[props.fieldName] : (multiple ? [] : null));
   

    const extendProps = {...props};
    const filterFields = ['formik','formFields','fieldName','onChange'];
    filterFields.forEach(key => delete extendProps[key]);

    let fieldlabel = label;
    if(useCms){
        fieldlabel = textContent[runningField.label];
    }

    const {onChange:additionalOnChange} = props;

    let optionTemplate = (option) => {
      if(option){
          const {label=''} = option
          return label;
      }
      return ''
    };

    if('optionTemplate' in props.formFields[props.fieldName]){
      optionTemplate = props.formFields[props.fieldName].optionTemplate;
    }
	
    
	 /* eslint-disable */
	 useEffect(() => {
		let val = values[props.fieldName];
		if(val){
			for(let i=0; i<options.length; i++){
				if(!Array.isArray(val)){
					val= [val];
				}
				for(let j=0;j<val.length; j+=1){
					if(String(options[i].value) === String(val[j])){
						console.log('match',val,options[i].value, options[i])
						setDefaultValue([options[i]]);
						break;
					}
				}
				
			}
			setLoaded(true);
		}
		
	 },[]);
	 /* eslint-enable */

	 console.log(values[props.fieldName],defaultValue);

	 if(loaded){
		return (
			<Autocomplete
				multiple={multiple}
				id="tags-filled"
				options={options}
				defaultValue={defaultValue}
				{...extendProps}
				renderTags={(value, getTagProps) =>
				value.map((option, index) => (
					<Chip variant="outlined" label={optionTemplate(option)} {...getTagProps({ index })} />
				))
				}
				onChange={(e,newValue) => {
				if(multiple){
					setValue(newValue);
				}else{
					setValue(newValue[0]);
				}console.log(newValue);
					props.formik.setFieldValue(fieldName, newValue)
					if(typeof additionalOnChange === 'function'){
						additionalOnChange(newValue)
					}
					
				}}
				getOptionLabel = {option => optionTemplate(option)}
				renderInput={(params) => (
				<TextField
					{...params}
					variant="standard"
					label={fieldlabel}
					placeholder=""
					{...extendProps}
				/>
				)}
			/>
		);
	}
	return null;
}
