import React from "react";
import { useNavigate } from "react-router-dom";
import MattersContextProvider from "./MattersContext";
import ResultsPage from "./ResultsPage";


export default function ArchivedResults(){
    const history = useNavigate();
    return (

        <MattersContextProvider history={history} archived>
            <ResultsPage/>
        </MattersContextProvider>
    );
}