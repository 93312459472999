import React, { useContext, useRef, useState } from 'react';

import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, SvgIcon } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { cLog } from './CustomLogger';
import { WidgetContext } from './Widgets/WidgetContext';
import { WidgetsContext } from './pages/pcdashboard/WidgetsContext';

// material
// ----------------------------------------------------------------------

export default function CustomMenuList(props) {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const {
        size='small'
    } = props;

    const {
        customButton=
        <IconButton size={size} ref={ref} className="menuOpener">
            <MenuIcon/>
        </IconButton>
    } = props;

    const widgetContext = useContext(WidgetContext);
    const widgetsContext = useContext(WidgetsContext);

    return (
        <>
            {React.cloneElement(customButton, {
                size,
                ref,
                onClick: (e) => {setIsOpen(true); e.stopPropagation();},
            })}
            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={(e) => {setIsOpen(false); e.stopPropagation();}}
                PaperProps={{
                    sx: { width: 300, maxWidth: '100%' }
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {props.options.map((item,index) => (


                    item.action ?
                            <MenuItem
                                key={index}
                                onClick={
                                        (e) => {
                                            
                                            item.action(props.id, props.context, widgetContext,widgetsContext);
                                            setIsOpen(false);
                                            e.stopPropagation();
                                        }
                                    }
                                >
                                <ListItemIcon>
                                    {item.iconComponent ? item.iconComponent : <SvgIcon component={item.icon} width={24} height={24} />}
                                </ListItemIcon>
                                <ListItemText primary={item.text} primaryTypographyProps={{variant: 'body2'}} />
                            </MenuItem>
                            :
                            <MenuItem
                                key={index}
                                onClick={
                                    (e) => {
                                        e.stopPropagation();
                                        window.location.href=item.to;
                                    }
                                }
                                >
                                <ListItemIcon>
                                    {item.iconComponent ? item.iconComponent : <SvgIcon component={item.icon} width={24} height={24} />}
                                </ListItemIcon>
                                <ListItemText primary={item.text} primaryTypographyProps={{variant: 'body2'}} />
                            </MenuItem>


                ))}
            </Menu>
        </>
    );
}
